export const loadTestEventIdeasState = (
  settings,
  ideas,
  setIdeas,
  userUid,
  eid
) => {
  if (settings.length === undefined) {
    if (settings.alpha_env === "green") {
      console.log("Load/Reload Event Ideas Test");
    }
    let uid = "";
    userUid ? (uid = userUid) : (uid = -1);
    let sendData = new FormData();

    sendData.append("title", settings.alpha_title);
    sendData.append("env", settings.alpha_env);
    sendData.append("release", settings.alpha_release);
    sendData.append("rlid", settings.alpha_rlid);
    sendData.append("todaysDate", settings.alpha_todaysDate);
    sendData.append("version", settings.event_version);

    sendData.append("eid", -1);
    sendData.append("uid", uid);
    sendData.append("lookupType", "searchMyIdeasTest");

    fetch("https://api.liltiffyrps.com/ideaDomain/V1/getIdea", {
      method: "post",
      body: sendData,
    })
      .then((response) => response.json())
      .then((events) => {
        switch (events.status_code) {
          case 200:
            setIdeas(events.body);
            break;
          case 300:
            setIdeas({ id: -100 });
            break;
          default:
            setIdeas({ id: -200 });
            break;
        }
        //console.log(models.body);
      });
  }
};
