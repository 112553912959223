import CityShow from "../cities/city-show-component";

const RegionView = (region) =>{
    //console.log(region)
    return(
        <div className='bg-dark rounded-4'
        style={{marginLeft: '1rem', paddingLeft: '1rem'}}>
            <h4><i className={region.region.icon}></i> <b>{region.region.name}</b></h4>
                            <p 
                            className="fs-lg text-light mb-3"><i>{region.region.subtitle}</i></p>
                            {region.region.cardText.split('<br>').map( (it, i) => <p className="text-light opacity-70 text-justify" key={'rgCt'+i}>{it}</p> )}
                            <CityShow city={region.region.city}></CityShow>
        
        </div>
    )
}

export default RegionView;