export const loadEventsState = (settings, events, setEvents, userUid) => {
  if (settings.length === undefined && events.length === 0) {
    if (settings.alpha_env === "green") {
      console.log("Load/Reload Events");
    }
    let uid = "";
    userUid ? (uid = userUid) : (uid = -1);
    let sendData = new FormData();

    sendData.append("title", settings.alpha_title);
    sendData.append("env", settings.alpha_env);
    sendData.append("release", settings.alpha_release);
    sendData.append("rlid", settings.alpha_rlid);
    sendData.append("todaysDate", settings.alpha_todaysDate);
    sendData.append("version", settings.event_version);

    sendData.append("eid", -1);
    sendData.append("uid", uid);
    sendData.append("lookupType", "searchMyEvents");

    fetch("https://api.liltiffyrps.com/eventDomain/V1/getEvent", {
      method: "post",
      body: sendData,
    })
      .then((response) => response.json())
      .then((events) => {
        switch (events.status_code) {
          case 200:
            setEvents(events.body);
            break;
          case 300:
            setEvents({ id: -100 });
            break;
          default:
            setEvents({ id: -200 });
            break;
        }
        //console.log(models.body);
      });
  }
};
