const CreateSingleLogPost = (post) => {

    //console.log(post);
    return (
        <div className='m-2 p-2 bg-dark rounded-4'>
            <p className='text-center'><b><u>{post.poster}</u></b></p>
            {post.post.split('<br>').map( (it, i) => <p className="text-light opacity-70 text-justify" key={'logPost'+i}>{it}</p> )}
        </div>  
        )
}

export default CreateSingleLogPost;