import CreateSingleLogPost from "./roleplayLogSinge.component";

const CreateLogArea = (log) => {
    const useLog = log.log;
    const posts = log.log.posts;
    //console.log(posts);
    let postArray=[];
    for (let i=1;i<=useLog.length;i++){
        postArray[i]={
            id: i,
            poster: posts[i].poster,
            post: posts[i].post
        }
        
    }
    return(
        <div>
        {postArray.map((post =>{
            //const { name, id, back, subtitle} = character;
            return(
                <div
                    className=''
                    key={post.id}
                    >
                    <CreateSingleLogPost 
                        post={post.post}
                        poster={post.poster}
                        
                    />
                </div>
                
            )
        }))
    }
        </div>
    )
    
    
}

export default CreateLogArea;