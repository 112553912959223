import { Fragment, useContext, useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import CharacterCardList from "../../conponents/character-card-list/character-card-list.component";
import { UserContext } from "../../context/user.content";

const CharacterSearchAll = () => {
  const { pageType } = useParams();
  //const pageType = params.pageType;
  const { searchBox, setSearchBox } = useContext(UserContext);
  const { hairRadio, eyeRadio } = useContext(UserContext);

  const [characters, setCharacters] = useState([]);
  let pageTitle = "";
  const [filterCharacters, setfilterCharacters] = useState(characters);
  const currentUserString = useContext(UserContext);
  const userUid = currentUserString.uid;
  //console.log(searchBox);
  switch (pageType) {
    case "pipeline":
      pageTitle = "Pipeline Characters";
      break;
    case "next":
      pageTitle = "Next Release Characters";
      break;
    case "unplanned":
      pageTitle = "Unplanned Characters";
      break;
    case "nextunplanned":
      pageTitle = "Next Release Unplanned Characters";
      break;
    case "every":
      pageTitle = "All Models";
      break;
    case "nextunplannedcheck":
      pageTitle = "Next Release Unplanned Characters with Pic Check";
      break;
    case "all":
    default:
      pageTitle = "All Characters";
      break;
  }

  useEffect(() => {
    //console.log(searchBox);
    //console.log (characters);
    let newFilterCharactersHair, newFilterCharactersEyes;
    if (characters.id > 0 || characters.length > 0) {
      const newFilterCharacters = characters.filter((character) => {
        return character.name.longName.toLocaleLowerCase().includes(searchBox);
      });
      if (eyeRadio !== "All" && eyeRadio !== null) {
        //console.log(newFilterCharacters);
        newFilterCharactersEyes = newFilterCharacters.filter(
          (eyesCharacter) => {
            //console.log(eyesCharacter);
            return eyesCharacter.eyes.name.includes(eyeRadio);
          }
        );
        //x = x.hair.name.toLocaleLowerCase().includes(hairRadio);
      } else {
        newFilterCharactersEyes = newFilterCharacters;
      }
      if (hairRadio !== "All" && hairRadio !== null) {
        //console.log(newFilterCharacters);
        newFilterCharactersHair = newFilterCharactersEyes.filter(
          (hairCharacter) => {
            //console.log(hairCharacter);
            return hairCharacter.hair.name.includes(hairRadio);
          }
        );
        //x = x.hair.name.toLocaleLowerCase().includes(hairRadio);
      } else {
        newFilterCharactersHair = newFilterCharactersEyes;
      }
      setfilterCharacters(newFilterCharactersHair);
    }
  }, [characters, searchBox, eyeRadio, hairRadio]);

  useEffect(() => {
    let uid = "";
    userUid ? (uid = userUid) : (uid = -1);
    //console.log(uid);
    //console.log(userUid);
    let sendData = new FormData();
    switch (pageType) {
      case "pipeline":
        sendData.append("lookupType", "searchPipelineShortCharacters");
        break;
      case "next":
        sendData.append("lookupType", "searchNextReleaseCharacters");
        break;
      case "unplanned":
        sendData.append("lookupType", "searchUnplannedCharacters");
        break;
      case "nextunplanned":
        sendData.append("lookupType", "searchNextUnplannedCharacters");
        break;
      case "every":
        sendData.append("lookupType", "searchEveryCharacters");
        break;
      case "nextunplannedcheck":
        sendData.append("lookupType", "searchNextUnplannedCharacters");
        sendData.append("picCheck", true);
        break;
      case "all":
      default:
        sendData.append("lookupType", "searchMyCharacters");
        break;
    }
    sendData.append("title", "meow");
    sendData.append("uid", uid);
    sendData.append("allowGets", false);
    sendData.append("genre", 1);
    sendData.append("version", 1);

    fetch("https://api.liltiffyrps.com/modelDomain/V1/getModelSearch", {
      method: "post",
      body: sendData,
    })
      .then((response) => response.json())
      .then((models) => {
        setSearchBox("");
        switch (models.status_code) {
          case 200:
            setCharacters(models.body);
            break;
          case 300:
            setCharacters({ id: -100 });
            break;
          default:
            setCharacters({ id: -200 });
            break;
        }
        //console.log(models.body);
      });
  }, [userUid, pageType]);

  return (
    <Fragment>
      <nav className="mb-3 pt-md-2 pt-lg-4" aria-label="Breadcrumb">
        <ol className="breadcrumb breadcrumb-light">
          <li className="breadcrumb-item">
            <Link to={`/`}>Home</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {pageTitle}
          </li>
        </ol>
      </nav>
      <div className="d-flex align-items-center justify-content-between pb-4 mb-2">
        <h1 className="text-light me-3 mb-0">{pageTitle}</h1>
        <div className="text-light">
          <i className="fa-duotone fa-person-dress"></i>
          <span className="align-middle">
            {" "}
            {filterCharacters.length}/{characters.length} Characters
          </span>
        </div>
      </div>
      <CharacterCardList
        characters={filterCharacters}
        idKey={Math.floor(Math.random() * 1000)}
      />
    </Fragment>
  );
};

export default CharacterSearchAll;
