import { Component } from "react";
import ChangeLogRelease from "./changeLog-Release.component";

//import CharacterCard from "../character-card/character-card.component";
//import "./character-card-list.styles.scss";

class ChangeLogList extends Component {
  render() {
    const { changeLog } = this.props;
    //let sizeText = "";
    //console.log('size');
    //console.log(changeLog);
    //const idKey = 1;
    /*
    switch (sizeing) {
      case 3:
        sizeText = "col-sm-6 col-md-4";
        break;
      default:
        sizeText = "col-sm-6";
        break;
    }*/

    return (
      <div className="row" key={`chlst-${0}`}>
        {changeLog.map((release) => {
          //const { name, id, back, subtitle} = character;
          return (
            <div className="col-12 mb-4" key={`chlg-${release.key}`}>
              <ChangeLogRelease release={release}></ChangeLogRelease>
            </div>
          );
        })}
        <hr />
      </div>
    );
  }
}

export default ChangeLogList;
