import { Outlet, useParams, Link } from "react-router-dom";

const EventSearch = () => {
  let pageTitle = "";
  const { pageType } = useParams();
  let showCrumb = false;
  let genreText = "";
  switch (pageType) {
    case "pipeline":
      pageTitle = "Pipeline Events";
      break;
    case "next":
      pageTitle = "Next Release Events";
      break;
    case "all":
    default:
      pageTitle = "Events";
      break;
  }

  const ShowMidCrumb = () => {
    if (showCrumb) {
      return (
        <li className="breadcrumb-item">
          <Link to={`/ideas/all`}>Events</Link>
        </li>
      );
    }
  };

  return (
    <div className="container pt-5 pb-lg-4 my-5">
      <nav className="mb-3 pb-md-1 pt-md-3" aria-label="Breadcrumb">
        <ol className="breadcrumb breadcrumb-light m-0">
          <li className="breadcrumb-item">
            <Link to={`/`}>Home</Link>
          </li>

          <ShowMidCrumb />

          <li className="breadcrumb-item active" aria-current="page">
            {pageTitle}
          </li>
        </ol>
      </nav>
      <h1 className="text-light mb-4">{pageTitle}</h1>
      <p>{genreText}</p>
      <Outlet />
    </div>
  );
};

export default EventSearch;
