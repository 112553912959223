import VillageShow from "../villages/village-show-component";

const CityView = (city) =>{
    //console.log(city)
    const CityViewStats = (stats) =>{
        //console.log(stats);
        if (stats.stats.is){
            return(
                <div className='mb-4'>
                    <h5 className='text-center'>General Information{/*city.city.name*/}</h5>
                    <p className='text-center'>
                        <b>Type:</b> {stats.stats.type} - <b>Size:</b> {stats.stats.size} - <b>Condition:</b> {stats.stats.condition} - <b>Age:</b> {stats.stats.age} - <b>Population Density:</b> {stats.stats.populationDensity} - <b>Population Wealth:</b> {stats.stats.populationWealth}
                    </p>
                </div>
                  
            )
        }
    }
    return(
        <div className='bg-dark rounded-4' key={`cityView${city.city.name}`}
        style={{marginLeft: '1rem', paddingLeft: '1rem'}}>
            <h4><i className={city.city.icon}></i> <b>{city.city.name}</b></h4>
                            <p 
                            className="fs-lg text-light mb-3"><i>{city.city.subtitle}</i></p>
                            {city.city.cardText.split('<br>').map( (it, i) => <p className="text-light opacity-70 text-justify" key={'ctCt'+i}>{it}</p> )}
                            <CityViewStats stats={city.city.stats}/>
                            <VillageShow village={city.city.village} />
        </div>
    )
}

export default CityView;