import StoryCard from "../story-card/story-card.component";

const StoryCardList = (stories) => {
    //console.log(stories);
    return (
        <div className ='row'
            
        >
            {stories.stories.map((story =>{
                //const { name, id, back, subtitle} = character;
                let divClass = '';
                switch (story.loopCount){
                    case 0:
                        divClass='col-12';
                        break;
                    case 1:
                    case 2:
                        divClass='col-12 col-md-6';
                        break;
                    default:
                        divClass='col-12 col-md-4';
                        break;



                }
                return(
                    <div 
                        className={divClass}
                        key={story.id}
                        >
                        <StoryCard 
                            story={story}
                        />
                    </div>
                    
                )
            }))
        }
            
        </div>
        );
}

export default StoryCardList;