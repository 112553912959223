import BadgePillsList from "../../conponents/badge-pills-list.component/badge-pills-list.component";
import { Link } from "react-router-dom";

const EventCard = (event) => {
  //console.log(event);
  const thisStory = event.event;

  //console.log(thisStory);
  switch (thisStory.loopCount) {
    case 0:
      return (
        <article className="pb-2 pb-md-1 mb-4 mb-md-5">
          <div className="row">
            <div className="col-md-7 col-lg-8 mb-lg-0 mb-3 mb-md-0">
              <Link className="d-block position-relative" to={thisStory.url}>
                <div className="position-absolute start-0 top-0 pt-3 ps-3">
                  <BadgePillsList character={thisStory} />
                </div>
                <img
                  className="rounded-3 character-card-pic"
                  src={thisStory.pictures.back}
                  alt="Post"
                />
              </Link>
            </div>
            <div className="col-md-5 col-lg-4">
              <h2 className="h5 text-light pt-1">
                <Link className="nav-link" to={thisStory.url}>
                  {thisStory.name.full.replace("\\", "")}
                </Link>
              </h2>
              <i>
                <Link
                  className="fw-lighter text-uppercase text-decoration-none"
                  to={thisStory.url}
                >
                  {thisStory.subtitle.replace("\\", "")}
                </Link>
              </i>
              <p className="d-md-none d-xl-block text-light opacity-70 mb-4">
                {thisStory.cardText.replace("\\", "")}
              </p>
              <p className="d-md-none d-xl-block text-light opacity-70 mb-4">
                {thisStory.date.start} - {thisStory.date.end}
              </p>

              {/*
                                <a 
                                className="d-flex align-items-center text-decoration-none" 
                                href={thisStory.url}>
                                <img 
                                    className="rounded-circle" 
                                    src="img/avatars/06.jpg" 
                                    width="48" 
                                    alt="Kristin Watson" />
                                <div 
                                    className="ps-2">
                                    <h6 
                                        className="fs-base text-light lh-base mb-1">Kristin Watson</h6>
                                    <div 
                                        className="d-flex fs-sm text-light opacity-70">
                                            <span 
                                                className="me-2 pe-1">
                                                <i 
                                                    className="fi-calendar-alt opacity-70 mt-n1 me-1"></i>May 13
                                            </span>
                                            <span>
                                                <i 
                                                    className="fi-chat-circle opacity-70 mt-n1 me-1"></i>No comments
                                            </span>
                                    </div>
                                </div>
            </a>*/}
            </div>
          </div>
        </article>
      );
    default:
    case 1:
    case 2:
      return (
        <article className="pb-2 pb-md-1">
          <Link className="d-block position-relative mb-3" to={thisStory.url}>
            <div className="position-absolute start-0 top-0 pt-3 ps-3">
              <BadgePillsList character={thisStory} />
            </div>
            <img
              className="d-block rounded-3 character-card-pic"
              src={thisStory.pictures.back}
              alt="Post"
            />
          </Link>
          {/*
                        <i><a 
                            className="fw-lighter text-uppercase text-decoration-none" 
            href={thisStory.url}>{thisStory.subtitle}</a></i>*/}
          <h3 className="h5 text-light mb-2 pt-1">
            <Link className=" nav-link" to={thisStory.url}>
              {thisStory.name.full.replace("\\", "")}
            </Link>
          </h3>
          <i>
            <Link
              className="fw-lighter text-uppercase text-decoration-none"
              to={thisStory.url}
            >
              {thisStory.subtitle.replace("\\", "")}
            </Link>
          </i>
          <p className="text-light opacity-70 mb-3">
            {thisStory.cardText.replace("\\", "")}
          </p>
          <p className="d-md-none d-xl-block text-light opacity-70 mb-4">
            {thisStory.date.start} - {thisStory.date.end}
          </p>

          {/*
                            <a 
                            className="d-flex align-items-center text-decoration-none" 
                            href={thisStory.url}>
                                <img 
                                    className="rounded-circle" 
                                    src="img/avatars/07.jpg" 
                                    width="48" 
                                    alt="Cody Fisher" />
                                <div className="ps-2">
                                    <h6 className="fs-base text-light lh-base mb-1">Cody Fisher</h6>
                                    <div className="d-flex fs-sm text-light opacity-70">
                                        <span className="me-2 pe-1">
                                            <i className="fi-calendar-alt opacity-70 mt-n1 me-1 align-middle"></i>May 08
                                        </span>
                                        <span>
                                            <i className="fi-chat-circle opacity-70 mt-n1 me-1 align-middle"></i>4 comments
                                        </span>
                                    </div>
                                </div>
            </a>*/}
        </article>
      );

    case -5:
      //default:
      return (
        <article className="pb-2 pb-md-1">
          <Link className="d-block position-relative mb-3" to={thisStory.url}>
            <div className="position-absolute start-0 top-0 pt-3 ps-3">
              <BadgePillsList character={thisStory} />
            </div>

            <img
              className="d-block rounded-3 character-card-pic"
              src={thisStory.pictures.back}
              alt="Post"
            />
          </Link>

          <h3 className="fs-base text-light pt-1">
            <Link className="nav-link" to={thisStory.url}>
              {thisStory.name.full.replace("\\", "")}
            </Link>
          </h3>
          <i>
            <Link
              className="fw-lighter text-uppercase text-decoration-none"
              to={thisStory.url}
            >
              {thisStory.subtitle.replace("\\", "")}
            </Link>
          </i>
          <p className="d-md-none d-xl-block text-light opacity-70 mb-4">
            {thisStory.date.start} - {thisStory.date.end}
          </p>

          {/*
                    <a 
                        className="d-flex align-items-center text-decoration-none" 
                        href={thisStory.url}>
                        <img 
                            className="rounded-circle" 
                            src="img/avatars/08.jpg" 
                            width="44" 
                            alt="Darrell Steward" />
                        <div className="ps-2">
                            <h6 className="fs-sm text-light lh-base mb-1">Darrell Steward</h6>
                            <div className="d-flex fs-xs text-light opacity-70">
                                <span className="me-2 pe-1">
                                    <i className="fi-calendar-alt opacity-70 mt-n1 me-1 align-middle"></i>Apr 15
                                </span>
                                <span>
                                    <i className="fi-chat-circle opacity-70 mt-n1 me-1 align-middle"></i>No comments
                                </span>
                            </div>
                        </div>
            </a>*/}
        </article>
      );
  }
};

export default EventCard;
