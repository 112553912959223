import { createContext, useState } from "react";
//import { getModels } from "../utils/v6/getModels";

import MODELS from '../shop-data.json';
//let MODEL_RESULTS =  getModels();
/*const MODELS = MODEL_RESULTS.characters;
console.log('test');
console.log (MODEL_RESULTS);
console.log (MODELS);*/

export const ModelsContext = createContext({
    models: [],
    
});

export const ModelsProvider = ({children}) => {
   const [models, setModels] = useState(MODELS);
   const value = { models};
    return (
        <ModelsContext.Provider value={value}>
            {children}
        </ModelsContext.Provider>
    )
    
}