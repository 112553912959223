import './authenitication.styles.scss';
import SignUpForm from '../../conponents/sign-up-form/sign-up-form.component';
import SignInForm from '../../conponents/sign-in-form/sign-in-form.component';

const Authenitication = () => {
    return (
        <div className='container'>
            <div
                className ='authentication-container row'
                >
                        <SignInForm />
                        <div className='col-0 col-md-2'/>
                        <SignUpForm />
            </div>
        </div>
    )
}

export default Authenitication;