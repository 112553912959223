import EventCard from "./event-card.component";

const EventCardList = (events) => {
  //console.log(events);
  return (
    <div className="row">
      {events.events.events.map((event) => {
        //const { name, id, back, subtitle} = character;
        let divClass = "";
        switch (event.loopCount) {
          case 0:
            divClass = "col-12";
            break;
          case 1:
          case 2:
            divClass = "col-12 col-md-6";
            break;
          default:
            divClass = "col-12 col-md-4";
            break;
        }
        return (
          <div className={divClass} key={event.id}>
            <EventCard event={event} />
          </div>
        );
      })}
    </div>
  );
};

export default EventCardList;
