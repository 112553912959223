import { Component } from "react";
import ChangeLogCategory from "./changeLog-Category.component";

//import CharacterCard from "../character-card/character-card.component";
//import "./character-card-list.styles.scss";

class ChangeLogRelease extends Component {
  render() {
    const { release } = this.props;
    //let sizeText = "";
    //console.log('size');
    //console.log(release);
    //const idKey = 1;
    /*
    switch (sizeing) {
      case 3:
        sizeText = "col-sm-6 col-md-4";
        break;
      default:
        sizeText = "col-sm-6";
        break;
    }*/

    return (
      <div className="card bg-secondary row p-4" key={`chlst-${0}`}>
        <h2>
          Release: {release.release} - {release.date}
        </h2>
        <p>
          v{release.version}.{release.update} - {release.description}
        </p>
        {release.categories.map((category) => {
          //const { name, id, back, subtitle} = character;
          return (
            <div className="col-12 mb-4" key={`rel-${category.key}`}>
              <ChangeLogCategory category={category}></ChangeLogCategory>
            </div>
          );
        })}
      </div>
    );
  }
}

export default ChangeLogRelease;
