const PlaceView = (place) =>{
    //console.log(land)
    return(
        <div className='bg-dark rounded-4'
        style={{marginLeft: '1rem', paddingLeft: '1rem'}}>
            <h4><i className={place.place.icon}></i> <b>{place.place.name}</b></h4>
                            <p 
                            className="fs-lg text-light mb-3"><i>{place.place.subtitle}</i></p>
                            {place.place.cardText.split('<br>').map( (it, i) => <p className="text-light opacity-70 text-justify" key={'plcCt'+i}>{it}</p> )}
                            
        
        </div>
    )
}

export default PlaceView;