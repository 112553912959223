import { Fragment, useState } from "react";
import "./character-search.styles.scss";
import { Outlet } from "react-router-dom";
import CharacterSearchForm from "../../components/character-search-form/character-search-form.components";

const CharacterSearch = () => {
  const [characters] = useState([]);

  if (characters === null) {
    //console.log('null');
    return <p>Loading...</p>;
  } else {
    if (characters.id === -100) {
      return <p>No characters found</p>;
    } else if (characters.id === -200) {
      return <p>Unknown Error loading page.</p>;
    } else {
      //console.log('not null');
      return (
        <Fragment>
          <main className="page-wrapper">
            <div className="container mt-5 mb-md-4 py-5">
              <div className="row py-md-1">
                <div className="col-lg-3 pe-xl-4 col-12 textBlack">
                  <div className="card floatDiv d-none d-lg-block">
                    <CharacterSearchForm />
                  </div>
                  <div className="card d-lg-none">
                    <CharacterSearchForm />
                  </div>
                </div>
                <div className="col-lg-9 col-12">
                  <Outlet />
                </div>
              </div>
            </div>
          </main>
        </Fragment>
      );
    }
  }
};

export default CharacterSearch;
