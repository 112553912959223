import { useState, useEffect, useContext } from 'react';
import { Link} from 'react-router-dom';
import { UserContext } from '../../../context/user.content';

const ModelStatsPanel = () => {
  const currentUserString = useContext(UserContext);
  const [modelStats, setmodelStats] = useState([]);
  //console.log('start access');
  //console.log(access);
  const userUid = currentUserString.uid;
 
  useEffect(()=>{
    let uid='';
    userUid ? (uid=userUid ):(uid=-1)
    //console.log (uid);
    let sendData = new FormData();
    sendData.append('title', 'meow');
    sendData.append('uid', uid);
    sendData.append('version', 1);
    
    fetch('https://api.liltiffyrps.com/modelDomain/V1/getModelStats', {
      method: 'post',
      body: sendData
      })
     
      .then(response=>response.json())
      .then(models => { 
        setmodelStats(models.body.counts);
        
        
      })
  },[userUid]);  

  return (
    <div
    className='siteStats card bg-dark' >
        <h2 className='panelHead'>Characters</h2>
        <p className='panelText'>My site contains a number of characters who have profiles with pictures, 
            physical description information, and varying degrees of background 
            and personality information. {modelStats.myCharacterCount} of my most popular characters are 
            visible to you right now. However, I currently have {modelStats.v7CharacterCount} modern characters and {modelStats.v7NonModernCharacterCount} other setting variants
            all with profiles on the site. Additionally, I have {modelStats.unplannedCharacterCount} characters 
            without profiles. These numbers grow all the time with {modelStats.pipelineCharacterCount} character 
            profiles currently being worked on, and {modelStats.nextCharacterCount} characters with profiles 
            and {modelStats.nextUnplannedCharacterCount} without profiles slated to be added with the next update. 
            I even have {modelStats.archiveCharacterCount} characters archived. In total that's {modelStats.totalCharacterCount} characters!</p>
        <div
            className='panelLink'>
            <Link 
                className='nav-link'
                to='/models/all'>
                View All Characters 
            </Link>
        </div>
</div>
  );

}
  

export default ModelStatsPanel;