import RoleplayCard from "../roleplay-card/roleplay-card.component";

const RoleplayCardList = (roleplays) => {
    //console.log(roleplays);
    return (
        <div className ='row'
            
        >
            {roleplays.roleplays.map((roleplay =>{
                //const { name, id, back, subtitle} = character;
                let divClass = '';
                switch (roleplay.loopCount){
                    case 0:
                        divClass='col-12';
                        break;
                    case 1:
                    case 2:
                        divClass='col-12 col-md-6';
                        break;
                    default:
                        divClass='col-12 col-md-4';
                        break;



                }
                //console.log(roleplay);
                return(
                    <div 
                        className={divClass}
                        key={roleplay.id}
                        >
                        <RoleplayCard 
                            roleplay={roleplay}
                        />
                    </div>
                    
                )
            }))
        }
            
        </div>
        );
}

export default RoleplayCardList;