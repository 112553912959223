import { useParams, Link } from "react-router-dom";
import { useEffect, useState, useContext, Fragment } from "react";
import { UserContext } from "../../context/user.content";
import BadgePillsList from "../../conponents/badge-pills-list.component/badge-pills-list.component";
import ImageGallery from "react-image-gallery";
import CharacterCardList from "../../conponents/character-card-list/character-card-list.component";
import "../../../node_modules/react-image-gallery/styles/scss/image-gallery.scss";
import "./character-detail.styles.scss";
import GroupsSection from "../../conponents/groups/groups.component";

const CharacterDetailPage = () => {
  const params = useParams();
  const modelId = params.modelId;

  const [characters, setCharacters] = useState([]);
  const currentUserString = useContext(UserContext);
  const userUid = currentUserString.uid;

  const BuildGallery = (images) => {
    const imageArray = images.images;
    //console.log(images)
    //console.log(imageArray)
    return <ImageGallery items={imageArray} showPlayButton={false} />;
  };

  const BuildFamily = (family) => {
    //console.log('family');
    //console.log(family);
    const { count, members, name, subtitle } = family.family;
    //console.log('count');
    //console.log(count);
    //console.log('members');
    //console.log(members);
    if (count > 0) {
      //console.log('in if');
      return (
        <div>
          <hr />
          <h2>
            {name} family ({count})
          </h2>
          <p>{subtitle}</p>
          <CharacterCardList characters={members} sizeing={3} />
        </div>
      );
    }
  };

  const BuildSpec = (spec) => {
    //console.log('family');
    //console.log(family);
    const { count, members } = spec.spec;
    //console.log('count');
    //console.log(count);
    //console.log('members');
    //console.log(members);
    if (count > 0) {
      //console.log('in if');
      return (
        <div>
          <hr />
          <h2>Special Relationships ({count})</h2>
          <CharacterCardList characters={members} sizeing={3} />
        </div>
      );
    }
  };

  const AgelessSection = (passed) => {
    const age = passed.age;
    const pipeline = passed.pipeline;
    //console.log (age);
    //console.log (pipeline);
    if (age.ageless !== "y") {
      if (pipeline !== "u") {
        return (
          <Fragment>
            <li className="mb-2">
              <strong>Birthday:</strong>
              <span className="opacity-70 ms-1">
                {age.birthMonth}/{age.birthDay}
              </span>
            </li>
            <li className="mb-2">
              <strong>Age:</strong>
              <span className="opacity-70 ms-1">{age.formatted}</span>
            </li>
            <li className="mb-2">
              <strong>Grade:</strong>
              <span className="opacity-70 ms-1">{age.gradeText}</span>
            </li>
          </Fragment>
        );
      } else {
        return (
          <li className="mb-2">
            <strong>Birthday:</strong>
            <span className="opacity-70 ms-1">
              {age.birthMonth}/{age.birthDay}
            </span>
          </li>
        );
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    let uid = "";
    userUid ? (uid = userUid) : (uid = -1);
    //console.log(uid);
    //console.log(userUid);
    let sendData = new FormData();
    sendData.append("title", "meow");
    sendData.append("lookupType", "searchThisCharacter");
    sendData.append("cid", modelId);
    sendData.append("uid", uid);

    fetch("https://api.liltiffyrps.com/modelDomain/V1/getModelSearch", {
      method: "post",
      body: sendData,
    })
      .then((response) => response.json())
      .then((models) => {
        //console.log(models.status_code);
        switch (models.status_code) {
          case 200:
            setCharacters(models.body[0]);
            break;
          case 300:
            setCharacters({ id: -100 });
            break;
          default:
            setCharacters({ id: -200 });
            break;
        }
        //console.log('Response:');
        //console.log(models.body[0]);
      });
  }, [userUid, modelId]);
  //console.log(params);
  //console.log(modelId);
  //console.log('Return:');
  //console.log(characters);
  if (characters.length === 0) {
    //console.log('null');
    return <p>Loading...</p>;
  } else {
    if (characters.id === -100) {
      return <p>No character found</p>;
    } else if (characters.id === -200) {
      return <p>Unknown Error loading page.</p>;
    } else {
      //console.log('not null');
      return (
        <div>
          <div className="container mt-5 mb-md-4 pb-5">
            <nav className="mb-3 pt-md-3" aria-label="Breadcrumb">
              <ol className="breadcrumb breadcrumb-light">
                <li className="breadcrumb-item">
                  <Link to={`/`}>Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={`/models/all`}>All Characters</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {characters.name.longName}
                </li>
              </ol>
            </nav>

            <div
              className="d-sm-flex align-items-end align-items-md-center justify-content-between position-relative mb-4"
              style={{ zIndex: 1025 }}
            >
              <div className="me-3">
                <h1 className="h2 text-light mb-md-0">
                  {characters.name.longName}
                </h1>
                <div className="d-md-none">
                  <div className="d-flex align-items-center mb-3">
                    <div className="h3 mb-0 text-light">
                      {characters.subtitle}
                    </div>
                    <div className="text-nowrap ps-3">
                      <BadgePillsList character={characters} />
                    </div>
                  </div>
                  <div className="d-flex flex-wrap align-items-center text-light mb-2">
                    <div className="text-nowrap border-end border-light pe-3 me-3">
                      <i className="fa-duotone fa-user-doctor-hair-long"></i>
                      <span className="align-middle">
                        {" "}
                        {characters.occupation}
                      </span>
                    </div>
                    <div className="text-nowrap">
                      <i className="fa-duotone fa-location-dot"></i>
                      <span className="align-middle">
                        {" "}
                        {characters.location}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-7">
                <BuildGallery images={characters.pictures.gallery} />
                <div className="py-3 mb-3">
                  <h2 className="h4 text-light mb-4">General Information</h2>
                  <div className="row text-light">
                    <div className="col-sm-6 col-md-12 col-lg-6">
                      <ul className="list-unstyled">
                        <li className="mb-2">
                          <strong>Ethnicity:</strong>
                          <span className="opacity-70 ms-1">
                            {characters.race.name}
                          </span>
                        </li>
                        <li className="mb-2">
                          <strong>Gender:</strong>
                          <span className="opacity-70 ms-1">
                            {characters.gender.name}
                          </span>
                        </li>
                        <AgelessSection
                          age={characters.age}
                          pipeline={characters.pipeline}
                        />
                      </ul>
                    </div>
                    <div className="col-sm-6 col-md-12 col-lg-6">
                      <ul className="list-unstyled">
                        <li className="mb-2">
                          <strong>Hair Color:</strong>
                          <span className="opacity-70 ms-1">
                            {characters.hair.name}
                          </span>
                        </li>
                        <li className="mb-2">
                          <strong>Eye Color:</strong>
                          <span className="opacity-70 ms-1">
                            {characters.eyes.name}
                          </span>
                        </li>
                        <li className="mb-2">
                          <strong>Height:</strong>
                          <span className="opacity-70 ms-1">
                            {characters.height}
                          </span>
                        </li>
                        <li className="mb-2">
                          <strong>Weight:</strong>
                          <span className="opacity-70 ms-1">
                            {characters.weight}
                          </span>
                        </li>
                        <li className="mb-2">
                          <strong>Measurements:</strong>
                          <span className="opacity-70 ms-1">
                            {characters.measurements}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-wrap border-top border-light fs-sm text-light pt-4 pb-5 pb-md-2">
                  <div className="border-end border-light pe-3 me-3">
                    <span className="opacity-70">
                      Published: <strong>{characters.dates.added}</strong>
                    </span>
                  </div>
                  <div className="border-end border-light pe-3 me-3">
                    <span className="opacity-70">
                      Updated: <strong>{characters.dates.updated}</strong>
                    </span>
                  </div>
                  <div className="border-end border-light pe-3 me-3">
                    <span className="opacity-70">
                      Model: <strong>{characters.modelName}</strong>
                    </span>
                  </div>
                  <div className="opacity-70">
                    Views: <strong>{characters.views.total}</strong>
                  </div>
                </div>
              </div>
              <div
                className="col-md-5 pt-5 pt-md-0"
                style={{ marginTop: "-6rem" }}
              >
                <div className="sticky-top pt-5">
                  <div className="d-none d-md-block pt-5">
                    <div className="d-flex mb-4">
                      <BadgePillsList character={characters} />
                    </div>
                    <div className="h3 text-light">{characters.subtitle}</div>
                    <div className="d-flex align-items-center text-light pb-4 mb-2">
                      <div className="text-nowrap border-end border-light pe-3 me-3">
                        <i className="fa-duotone fa-user-doctor-hair-long"></i>
                        <span className="align-middle">
                          {" "}
                          {characters.occupation}
                        </span>
                      </div>
                      <div className="text-nowrap">
                        <i className="fa-duotone fa-location-dot"></i>
                        <span className="align-middle">
                          {" "}
                          {characters.location}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="pb-4 mb-3">
                    <p className="text-light opacity-70 mb-1">
                      {characters.cardText}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <BuildFamily family={characters.family} />
          <BuildSpec spec={characters.spec} />
          <GroupsSection groups={characters.groups} />
        </div>
      );
    }
  }
};

export default CharacterDetailPage;
