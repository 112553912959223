import { useEffect, useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { UserContext } from "../../context/user.content";
import PlanetShow from "./planets/planet-show-component";

const RoleplaySetting = () => {
    //let showCount = 0;
    const currentUserString = useContext(UserContext);
    const [rp] = useState([]);
    const [roleplay, setRoleplay] = useState([]);
    const userUid = currentUserString.uid;
    const params = useParams();
    const rpId = params.rpId;

    useEffect(()=>{
        let uid='';
        userUid ? (uid=userUid ):(uid=-1)
        //console.log (uid);
        let sendData = new FormData();
        //console.log(uid);
        //console.log(rp);

        
        sendData.append('title', 'meow');
        //sendData.append('lookupType', 'roleplayByKey');
        sendData.append('uid', uid);
        sendData.append('rid', rpId);
        sendData.append('version', 1);
        
        fetch('https://api.liltiffyrps.com/roleplayDomain/V1/getRoleplaySetting', {
          method: 'post',
          body: sendData
          })
         
          .then(response=>response.json())
          .then(models => { 
            switch(models.status.code){
                case 200:
                    setRoleplay(models.rp);
                break;
                default:
                case 300:
                    setRoleplay({id: -100})
                break;

                
            }
            //console.log(models.rp);
            setRoleplay(models.rp);
          })
            
      },[userUid, rpId, rp]); 

      

    
    
    

    //console.log(roleplay);
    if (roleplay==null){
        return(<p>Loading...</p>);
    } else {
        if (roleplay.id > 0){
    return(
        <div 
            className="container pt-5 pb-lg-4 my-5">
            <nav 
                className="mb-3 pb-md-1 pt-md-3" 
                aria-label="Breadcrumb">
                <ol 
                    className="breadcrumb breadcrumb-light">
                    <li 
                        className="breadcrumb-item">
                        <Link 
                            to={`/`}>Home
                        </Link>
                        
                    </li>
                    <li 
                        className="breadcrumb-item">
                        
                        <Link 
                            to={`/myRoleplays`}>My Roleplays
                        </Link>
                        
                    </li>
                    <li 
                        className="breadcrumb-item active" 
                        aria-current="page">{roleplay.setting.name}
                    </li>
              </ol>
            </nav>
            <h1 
                className="h2 text-light pb-3">{roleplay.setting.name} Setting
            </h1>
            <Link to={`/roleplay/${roleplay.id}`}>Back To Roleplay</Link>
            <div className='bg-secondary m-2 p-2 rounded-4'>
                <h4>Setting Info</h4>
                <div className='bg-dark rounded-4 p-2 m-2'>
                    <p 
                        className="fs-lg text-light mb-3"><i>{roleplay.setting.subtitle}</i></p>
                    {roleplay.setting.cardText.split('<br>').map( (it, i) => <p className="text-light opacity-70 text-justify" key={'setCt'+i}>{it}</p> )}
                    <hr />
                    <p className='text-center'>
                        <b>Magic:</b> {roleplay.setting.magic.name}
                    </p>
                    {roleplay.setting.magic.cardText.split('<br>').map( (it, i) => <p className="text-light opacity-70 text-justify" key={'setMg'+i}>{it}</p> )}
                    <p className='text-center'>
                        <b>Magic Rarity:</b> {roleplay.setting.magicRarity.name}
                    </p>
                    {roleplay.setting.magicRarity.cardText.split('<br>').map( (it, i) => <p className="text-light opacity-70 text-justify" key={'setMR'+i}>{it}</p> )}
                    <p className='text-center'>
                        <b>Races:</b> {roleplay.setting.races.name}
                    </p>
                    {roleplay.setting.races.cardText.split('<br>').map( (it, i) => <p className="text-light opacity-70 text-justify" key={'setRc'+i}>{it}</p> )}
                    <p className='text-center'>
                        <b>Technology:</b> {roleplay.setting.tech.name}
                    </p>
                    {roleplay.setting.tech.cardText.split('<br>').map( (it, i) => <p className="text-light opacity-70 text-justify" key={'setTc'+i}>{it}</p> )}
                        
                </div>
            </div>
            <div className='bg-secondary m-2 p-2 rounded-4'>
                <h4>Setting Locations</h4>
                <PlanetShow planet={roleplay.setting.planet}/>
            </div>
            {/*
            <img 
                className="rounded-3 character-card-pic" 
                src={roleplay.pictures.back} 
                alt="fall 22" />
            <div 
                className="row mt-4 pt-3">
                <div 
                    className="col-lg-8">

                </div>
            </div>
            <div 
                className="d-flex flex-wrap border-bottom border-light pb-3 mb-4">
                <Link 
                    className="text-uppercase text-decoration-none border-end border-light pe-3 me-3 mb-2" 
                    to={roleplay.story.url}>
                    View Story Idea
                </Link>
                <div 
                className="d-flex align-items-center text-light border-end border-light pe-3 me-3 mb-2">
                
                <span>{roleplay.genre.name}</span>
            </div>
                <div 
                    className="d-flex align-items-center text-light border-end border-light pe-3 me-3 mb-2">
                    
                    <span>{roleplay.male.name} Age: {roleplay.male.age}</span>
                </div>
                <div 
                className="d-flex align-items-center text-light border-end border-light pe-3 me-3 mb-2">
                
                <span>Ages: X = {roleplay.fage}</span>
            </div>
                {/*
                <div 
                    className="d-flex align-items-center text-light border-end border-light pe-3 me-3 mb-2">
                    <i 
                        className="fi-calendar-alt opacity-70 me-2">
                    </i>
                    <span>Oct 18</span>
                    </div>*/}
                {/*
                <div 
                    className="d-flex align-items-center text-light border-end border-light pe-3 me-3 mb-2">
                    <i 
                        className="fi-clock opacity-70 me-2">
                    </i>
                    <span>6 min read</span>
                </div>
                <a 
                    className="d-flex align-items-center text-light text-decoration-none mb-2" 
                    href="#comments" 
                    data-scroll>
                    <i 
                        className="fi-chat-circle opacity-70 me-2">
                    </i>
                    <span>3 comments</span>
                </a>
                    *//*
            </div>
            <p 
                className="fs-lg fw-bold text-light mb-4">{roleplay.subtitle}</p>
            <p 
                className="text-light opacity-70 text-justify">{roleplay.cardText}</p>

            <ShowLocation
                roleplay = {roleplay}
                />
                */}
            
        </div>


    )}}
}





export default RoleplaySetting;