import { createContext, useState, useEffect } from "react";
import { onAuthStateChangedListener } from "../utils/firebase/firebase.utils";
import { createUserDocuementFromAuth } from "../utils/firebase/firebase.utils";
// As the actual value you want to access
export const UserContext = createContext({
  currentUser: null,
  setCurrentUser: () => null,
  uid: "",
  setUid: () => null,
  uA: {
    aP: false,
    aN: false,
    aU: false,
  },
  setUa: () => null,
  displayName: null,
  setDisplayName: () => null,
  rp: {
    active: false,
    fage: null,
    key: null,
  },
  setRp: () => null,
  mt: "m",
  setMt: () => null,
  em: false,
  setEm: () => null,
  ek: null,
  setEk: () => null,
  userUa: {
    aP: null,
    aN: null,
    aU: null,
    mt: "m",
    em: false,
    displayName: null,
    rp: {
      active: false,
      fage: -100,
      key: -100,
    },
  },
  setUserUa: () => null,
  searchBox: null,
  setSearchBox: () => null,
  hairRadio: null,
  setHairRadio: () => null,
  eyeRadio: null,
  setEyeRadio: () => null,
  genderRadio: null,
  setGenderRadio: () => null,
  raceRadio: null,
  setRaceRadio: () => null,
});

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [uid, setUid] = useState(null);
  const [displayName, setDisplayName] = useState(null);
  const [uA, setUa] = useState(null);
  const [rp, setRp] = useState(null);
  const [mt, setMt] = useState(null);
  const [em, setEm] = useState(null);
  const [ek, setEk] = useState(null);
  const [searchBox, setSearchBox] = useState(null);
  const [hairRadio, setHairRadio] = useState(null);
  const [eyeRadio, setEyeRadio] = useState(null);
  const [genderRadio, setGenderRadio] = useState(null);
  const [raceRadio, setRaceRadio] = useState(null);
  const [userUa, setUserUa] = useState(null);
  const value = {
    currentUser,
    setCurrentUser,
    uid,
    setUid,
    displayName,
    setDisplayName,
    uA,
    setUa,
    rp,
    setRp,
    mt,
    setMt,
    em,
    setEm,
    ek,
    setEk,
    userUa,
    setUserUa,
    searchBox,
    setSearchBox,
    hairRadio,
    setHairRadio,
    eyeRadio,
    setEyeRadio,
    genderRadio,
    setGenderRadio,
    raceRadio,
    setRaceRadio,
  };
  //console.log(uA);
  useEffect(() => {
    const unsubscribe = onAuthStateChangedListener((user) => {
      if (user) {
        createUserDocuementFromAuth(user);
        setUid(user.uid);
        setDisplayName(user.displayName);
        setUa({
          aP: false,
          aN: false,
          aU: false,
        });
        setRp({
          active: false,
          fage: null,
          key: null,
        });
        setMt("m");
        setEk(null);
        setEm(false);
        setUserUa({
          aP: null,
          aN: null,
          aU: null,
          mt: "m",
          em: false,
          displayName: null,
          rp: {
            active: false,
            fage: -100,
            key: -100,
          },
        });
      } else {
        setUid("");
        setDisplayName("");
        setUa({
          aP: false,
          aN: false,
          aU: false,
        });
        setRp({
          active: false,
          fage: null,
          key: null,
        });
        setMt("m");
        setEk(null);
        setEm(false);
        setUserUa({
          aP: null,
          aN: null,
          aU: null,
          mt: "m",
          em: false,
          displayName: null,
          rp: {
            active: false,
            fage: -100,
            key: -100,
          },
        });
      }

      //console.log(user);
      //console.log(user.uid);
      setCurrentUser(user);
    });

    return unsubscribe;
  }, []);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
