import { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import StoryCardList from "../../conponents/story-card-list/story-card-list.component";
import { UserContext } from "../../context/user.content";
import { retrieveSiteSettings } from "../../utils/firebase/firebase.utils";

const StorySearches = () => {
  const { pageType } = useParams();
  //const pageType = params.pageType;
  const [stories, setStories] = useState([]);
  const [settings, setSettings] = useState([]);

  const currentUserString = useContext(UserContext);
  const userUid = currentUserString.uid;

  useEffect(() => {
    if (settings.length === 0) {
      const fetchSettings = async () => {
        setSettings(await retrieveSiteSettings());
      };
      fetchSettings();
    }
  }, []);

  useEffect(() => {
    if (settings.length !== 0) {
      let uid = "";
      userUid ? (uid = userUid) : (uid = -1);
      let sendData = new FormData();

      sendData.append("title", settings.alpha_title);
      sendData.append("env", settings.alpha_env);
      sendData.append("release", settings.alpha_release);
      sendData.append("rlid", settings.alpha_rlid);
      sendData.append("todaysDate", settings.alpha_todaysDate);
      sendData.append("version", settings.idea_version);

      sendData.append("uid", uid);

      switch (pageType) {
        case "modern":
          sendData.append("lookupType", "searchIdeasByGenre");
          sendData.append("gid", 1);
          break;
        case "gamer":
          sendData.append("lookupType", "searchIdeasByGenre");
          sendData.append("gid", 7);
          break;
        case "isekai":
          sendData.append("lookupType", "searchIdeasByGenre");
          sendData.append("gid", 13);
          break;
        case "fantasy":
          sendData.append("lookupType", "searchIdeasByGenre");
          sendData.append("gid", 2);
          break;
        case "animefantasy":
          sendData.append("lookupType", "searchIdeasByGenre");
          sendData.append("gid", 12);
          break;
        case "monster":
          sendData.append("lookupType", "searchIdeasByGenre");
          sendData.append("gid", 3);
          break;
        case "goddess":
          sendData.append("lookupType", "searchIdeasByGenre");
          sendData.append("gid", 4);
          break;
        case "fairytale":
          sendData.append("lookupType", "searchIdeasByGenre");
          sendData.append("gid", 5);
          break;
        case "witch":
          sendData.append("lookupType", "searchIdeasByGenre");
          sendData.append("gid", 6);
          break;
        case "superhero":
          sendData.append("lookupType", "searchIdeasByGenre");
          sendData.append("gid", 8);
          break;
        case "historical":
          sendData.append("lookupType", "searchIdeasByGenre");
          sendData.append("gid", 9);
          break;
        case "animal":
          sendData.append("lookupType", "searchIdeasByGenre");
          sendData.append("gid", 10);
          break;
        case "scifi":
          sendData.append("lookupType", "searchIdeasByGenre");
          sendData.append("gid", 11);
          break;

        case "pipeline":
          sendData.append("lookupType", "searchPipelineIdeas");
          break;
        case "next":
          sendData.append("lookupType", "searchNextReleaseIdeas");
          break;
        case "every":
          sendData.append("lookupType", "searchEveryIdeas");
          break;
        case "all":
        default:
          sendData.append("lookupType", "searchMyIdeas");
          break;
      }

      fetch("https://api.liltiffyrps.com/ideaDomain/V1/getIdea", {
        method: "post",
        body: sendData,
      })
        .then((response) => response.json())
        .then((models) => {
          switch (models.status_code) {
            case 200:
              //console.log(models.body.ideas);
              setStories(models.body.ideas);
              break;
            case 300:
              setStories({ id: -100 });
              break;
            default:
              setStories({ id: -200 });
              break;
          }
          //console.log(models.body);
        });
    }
  }, [userUid, pageType, settings]);

  //console.log(stories);
  if (stories.length === 0) {
    return <p>loading...</p>;
  } else {
    //console.log('in else');
    for (let i = 0; i < stories.length; i++) {
      //console.log(stories[i]);
      return <StoryCardList stories={stories} />;
    }
  }
};

export default StorySearches;
