import { Component } from "react";

import CharacterCard from '../character-card/character-card.component';
import './character-card-list.styles.scss';

class CharacterCardList extends Component{
    render(){
        const { characters, idKey, sizeing } = this.props;
        let sizeText='';
        //console.log('size');
        //console.log(sizeing);
        switch(sizeing){
            case 3:
                sizeText='col-sm-6 col-md-4';
                break;
            default:
                sizeText='col-sm-6';
                break;

        }
        return (
        <div 
            className='row' 
            key={`cl-${idKey}`}
        >
            {characters.map((character =>{
                //const { name, id, back, subtitle} = character;
                return(
                    <div
                        className={`${sizeText} mb-4`}
                        key={`cl-${idKey}-${character.id}`}>
                        <CharacterCard 
                            character={character}
                        />
                    </div>
                    
                )
            }))
        }
            
        </div>
        );
    }
}

export default CharacterCardList;