import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../context/user.content";
import EventCardList from "../../components/event-card-list/event-card-list.component";

const EventSearches = (passes) => {
  //console.log("settings");
  //console.log(passes);
  //console.log("settings");
  //console.log(settings);
  //console.log("events");
  //console.log(events);
  //console.log("set");
  //console.log(setEvents);
  const { pageType } = useParams();
  const currentUserString = useContext(UserContext);
  const userUid = currentUserString.uid;

  useEffect(() => {
    /*
    console.log(passes.settings);
    console.log(userUid);
    console.log(passes.events);
    //console.log(passes.settings);
    console.log(passes.settings.length);
    console.log(passes.events.length);
    */

    if (passes.settings.length === undefined && passes.events.length === 0) {
      if (passes.settings.alpha_env === "green") {
        console.log("Load/Reload Events");
      }
      let uid = "";
      userUid ? (uid = userUid) : (uid = -1);
      let sendData = new FormData();

      sendData.append("title", passes.settings.alpha_title);
      sendData.append("env", passes.settings.alpha_env);
      sendData.append("release", passes.settings.alpha_release);
      sendData.append("rlid", passes.settings.alpha_rlid);
      sendData.append("todaysDate", passes.settings.alpha_todaysDate);
      sendData.append("version", passes.settings.event_version);

      sendData.append("eid", -1);
      sendData.append("uid", uid);

      switch (pageType) {
        case "pipeline":
          //sendData.append("lookupType", "searchPipelineShortCharacters");
          break;
        case "next":
          //sendData.append("lookupType", "searchNextReleaseCharacters");
          break;
        case "all":
        default:
          sendData.append("lookupType", "searchMyEvents");
          break;
      }

      fetch("https://api.liltiffyrps.com/eventDomain/V1/getEvent", {
        method: "post",
        body: sendData,
      })
        .then((response) => response.json())
        .then((events) => {
          switch (events.status_code) {
            case 200:
              passes.setEvents(events.body);
              break;
            case 300:
              passes.setEvents({ id: -100 });
              break;
            default:
              passes.setEvents({ id: -200 });
              break;
          }
          //console.log(models.body);
        });
    }
  }, [pageType, userUid, passes, passes.settings]);

  //console.log(events);
  if (passes.events.length === 0) {
    return <p>loading...</p>;
  } else {
    //console.log('in else');
    return <EventCardList events={passes.events} />;
  }
};

export default EventSearches;
