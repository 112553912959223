import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import { siteSettings } from "../../support/siteSettings.settings";

const firebaseConfig = {
  apiKey: "AIzaSyAdAr1Z6d_UfNJOMVBaMdyCGortccz57II",
  authDomain: "liltiffyroleplays.firebaseapp.com",
  projectId: "liltiffyroleplays",
  storageBucket: "liltiffyroleplays.appspot.com",
  messagingSenderId: "648919379893",
  appId: "1:648919379893:web:fafed4c4485f6f9d00b3fc",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({
  prompt: "select_account",
});

export const auth = getAuth();
export const signInWithGooglePopup = () =>
  signInWithPopup(auth, googleProvider);

export const fireStoreDb = getFirestore();

export const retrieveSiteSettings = async () => {
  const siteSettingsDoc = doc(fireStoreDb, "siteSettings", siteSettings.env);
  const siteSettingsResponse = await getDoc(siteSettingsDoc);
  if (siteSettingsResponse.exists()) {
    //console.log("Document data:", siteSettingsResponse.data());
    let dataResponse = siteSettingsResponse.data();
    return dataResponse;
    //return dataResponse;
    //console.log(dataResponse.release);
  } else {
    return "";
    // docSnap.data() will be undefined in this case
    //console.log("No such document!");
  }
  //console.log(siteSettingsResponse);
  //console.log(siteSettingsResponse._document.data.value.mapvalue[0].release);
};

export const createUserDocuementFromAuth = async (
  userAuth,
  additionInformation = {}
) => {
  if (!userAuth) return;

  const userDocRef = doc(fireStoreDb, "users", userAuth.uid);

  const userIntoDB = (displayName, uid) => {
    console.log(displayName);
    console.log(uid);
    let sendData = new FormData();
    sendData.append("title", "meow");
    sendData.append("uid", uid);
    sendData.append("displayName", displayName);

    fetch("https://api.liltiffyrps.com/userDomain/V1/setUser", {
      method: "post",
      body: sendData,
    });

    /*
    .then(response=>response.json())
    .then(models => { 
      switch (models.status_code)
        {
            case 200:
                //setCharacters(models.body);
                break;
            case 300:
                //setCharacters({id: -100});
                break;
            default:
                //setCharacters({id: -200});
                break;
        }
      //console.log(models.body);
    })
    */
  };
  //console.log(userDocRef);

  //const db=firebase.firestore();
  //export default db;

  const userSnapshot = await getDoc(userDocRef);

  //console.log(userSnapshot.exists());
  //console.log(userSnapshot);
  //const userMap = userSnapshot.docs.reduce

  if (!userSnapshot.exists()) {
    const { displayName, email } = userAuth;
    const createdAt = new Date();
    const aP = false;
    const aU = false;
    const aN = false;

    try {
      await userIntoDB(userAuth.displayName, userAuth.uid);
    } catch (error) {
      //console.log(error);
      alert("Error");
      console.log("Error creating user: ", error.code);
    }

    try {
      await setDoc(userDocRef, {
        displayName,
        email,
        createdAt,
        aP,
        aU,
        aN,
        ...additionInformation,
      });
    } catch (error) {
      //console.log(error);
      alert("Error");
      console.log("Error creating user: ", error.code);
    }
  }

  return userDocRef;
};

export const createAuthUserWithEmailAndPassword = async (email, password) => {
  if (!email || !password) return;

  return await createUserWithEmailAndPassword(auth, email, password);
};

export const signInUserWithEmailAndPassword = async (email, password) => {
  if (!email || !password) return;

  return await signInWithEmailAndPassword(auth, email, password);
};

export const signOutUser = async () => await signOut(auth);

export const onAuthStateChangedListener = (callback) =>
  onAuthStateChanged(auth, callback);
