import BadgePillsList from "../../conponents/badge-pills-list.component/badge-pills-list.component";
import { Link } from "react-router-dom";

const IdeaCard = (idea) => {
  //console.log (story);
  const thisStory = idea.idea;
  //console.log(thisStory);
  return (
    <article className="pb-2 pb-md-1">
      <Link className="d-block position-relative mb-3" to={thisStory.url}>
        <div className="position-absolute start-0 top-0 pt-3 ps-3">
          <BadgePillsList character={thisStory} />
        </div>
        <img
          className="d-block rounded-3 character-card-pic"
          src={thisStory.pictures.back}
          alt="Post"
        />
      </Link>
      <Link
        className="fw-lighter text-uppercase text-decoration-none"
        to={thisStory.genre.url}
      >
        {thisStory.genre.name}
      </Link>
      <br />
      <h3 className="h5 text-light mb-2 pt-1">
        <Link className=" nav-link" to={thisStory.url}>
          {thisStory.name.replace("\\", "")}
        </Link>
      </h3>
      <i>
        <Link
          className="fw-lighter text-uppercase text-decoration-none"
          to={thisStory.url}
        >
          {thisStory.subtitle.replace("\\", "")}
        </Link>
      </i>
      <p className="text-light opacity-70 mb-3">
        {thisStory.cardText.replace("\\", "")}
      </p>
    </article>
  );
};

export default IdeaCard;
