import { Component } from "react";
import ChangeLogUpdate from "./changeLog-Update.component";

//import CharacterCard from "../character-card/character-card.component";
//import "./character-card-list.styles.scss";

class ChangeLogCategory extends Component {
  render() {
    const { category } = this.props;
    //let sizeText = "";
    //console.log('size');
    //console.log(category);
    //const idKey = 1;
    /*
    switch (sizeing) {
      case 3:
        sizeText = "col-sm-6 col-md-4";
        break;
      default:
        sizeText = "col-sm-6";
        break;
    }*/

    return (
      <div className="card bg-dark row p-3" key={`chlst-${0}`}>
        <h3>{category.name}</h3>
        {category.updates.map((update) => {
          //const { name, id, back, subtitle} = character;
          return (
            <div className={`col-12 mb-4`} key={`cat-${update.key}`}>
              <ChangeLogUpdate update={update}></ChangeLogUpdate>
            </div>
          );
        })}
      </div>
    );
  }
}

export default ChangeLogCategory;
