import CityView from "./city-view.component";

const CityShow = (city) => {
    //console.log(city);
    if (city.city.is){
        //console.log(planet.planet.planets);
        return (
            <div >
            {city.city.cities.map(thisCity => {
                
                return(
                    <div key={`cityView-${thisCity.id}`}>
                        <CityView city={thisCity}>
                        </CityView> 
                    </div>)
            })}
            
            </div>
        )
        
            }
    
}

export default CityShow;