import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AnimeBlock from "./anime-block";
//import Anime from "../../anime.json";

const Fall22 = () => {
  const { seasonId } = useParams();
  const [anime, setAnime] = useState([]);
  let season = 1;
  let title = "";
  let date = "";
  let pageText = "";
  let mainPic = "";
  if (anime.length > 0) {
    mainPic = "https://images.liltiffyrps.com/backs/" + anime[0].back;
  }
  switch (seasonId) {
    case "fall22":
      season = 1;
      title = "Lil Tiffy's Fall 2022 Anime Rank and Review";
      date = "Oct 18";
      pageText =
        "The Fall 2022 season of anime on Crunkyroll and Hidive have a number of really good shows.  Below is both my ranking and review of the shows I'm watching.  If I have story ideas based off an anime the link to the idea will be included.";

      break;
    case "winter23":
      season = 2;
      title = "Lil Tiffy's Winter 2023 Anime Rank and Review";
      date = "Jan 20";
      pageText =
        "The Winter 2023 season of anime on Crunkyroll and Hidive have a number of really good shows.  Below is both my ranking and review of the shows I'm watching.  If I have story ideas based off an anime the link to the idea will be included.";
      break;
    case "spring23":
      season = 3;
      title = "Lil Tiffy's Spring 2023 Anime Rank and Review";
      date = "July 15";
      pageText =
        "The Spring 2023 season of anime on Crunkyroll and Hidive have a number of really good shows.  Below is both my ranking and review of the shows I'm watching.  If I have story ideas based off an anime the link to the idea will be included.";
      break;
    case "summer23":
      season = 4;
      title = "Lil Tiffy's Summer 2023 Anime Rank and Review";
      date = "July 15";
      pageText =
        "The Summer 2023 season of anime on Crunkyroll and Hidive have a number of really good shows.  Below is both my ranking and review of the shows I'm watching.  If I have story ideas based off an anime the link to the idea will be included.";
      break;
    case "all":
      season = -1;
      title = "Lil Tiffy's Anime Rank and Review";
      date = "Nov 11";
      break;

    default:
      season = -1;
  }
  useEffect(() => {
    let sendData = new FormData();
    sendData.append("title", "meow");
    sendData.append("version", 1);
    sendData.append("current", 4);
    switch (seasonId) {
      case "fall22":
        sendData.append("season", 1);
        break;
      case "winter23":
        sendData.append("season", 2);
        break;
      case "spring23":
        sendData.append("season", 3);
        break;
      case "summer23":
        sendData.append("season", 4);
        break;

      case "all":
        sendData.append("season", -1);
        break;

      default:
        sendData.append("season", -2);
        break;
    }

    fetch("https://api.liltiffyrps.com/ideaDomain/V1/getAnimesBySeason", {
      method: "post",
      body: sendData,
    })
      .then((response) => response.json())
      .then((models) => {
        switch (models.status_code) {
          case 200:
            setAnime(models.body);
            break;
          case 300:
            setAnime({ id: -100 });
            break;
          default:
            setAnime({ id: -200 });
            break;
        }
        //console.log(models.body);
      });
  }, [seasonId]);

  const ShowOtherCats = () => {
    if (seasonId === "all") {
      return (
        <div>
          <Link
            className="text-uppercase text-decoration-none border-end border-light pe-3 me-3 mb-2"
            to="/anime/summer23"
          >
            Summer 2023
          </Link>

          <Link
            className="text-uppercase text-decoration-none border-end border-light pe-3 me-3 mb-2"
            to="/anime/spring23"
          >
            Spring 2023
          </Link>
          <Link
            className="text-uppercase text-decoration-none border-end border-light pe-3 me-3 mb-2"
            to="/anime/winter23"
          >
            Winter 2023
          </Link>
          <Link
            className="text-uppercase text-decoration-none border-end border-light pe-3 me-3 mb-2"
            to="/anime/fall22"
          >
            Fall 2022
          </Link>
        </div>
      );
    } else {
      return;
    }
  };
  const AnimeBlocks = () => {
    let count = 0;
    //console.log(anime);
    return (
      <div key={`animeList${season}`}>
        {anime.map((animeShow) => {
          //console.log(animeShow);
          //console.log(count);

          //const { name, id, back, subtitle} = character;
          return (
            <div key={animeShow.code}>
              <AnimeBlock
                anime={animeShow}
                counter={(count = count + 1)}
              ></AnimeBlock>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="container pt-5 pb-lg-4 my-5">
      <nav className="mb-3 pb-md-1 pt-md-3" aria-label="Breadcrumb">
        <ol className="breadcrumb breadcrumb-light">
          <li className="breadcrumb-item">
            <Link to={`/`}>Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/anime/all`}>Anime</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {title}
          </li>
        </ol>
      </nav>
      <h1 className="h2 text-light pb-3">{title}</h1>
      <img
        className="rounded-3 character-card-pic"
        src={mainPic}
        alt={seasonId}
      />

      <div className="row mt-4 pt-3">
        <div className="col-lg-8"></div>
      </div>
      <div className="d-flex flex-wrap border-bottom border-light pb-3 mb-4">
        <Link
          className="text-uppercase text-decoration-none border-end border-light pe-3 me-3 mb-2"
          to="/anime/all"
        >
          Anime
        </Link>
        <div className="d-flex align-items-center text-light border-end border-light pe-3 me-3 mb-2">
          <i className="fi-calendar-alt opacity-70 me-2"></i>
          <span>{date}</span>
        </div>
        <ShowOtherCats />
        {/*
                <div 
                    className="d-flex align-items-center text-light border-end border-light pe-3 me-3 mb-2">
                    <i 
                        className="fi-clock opacity-70 me-2">
                    </i>
                    <span>6 min read</span>
                </div>
                <a 
                    className="d-flex align-items-center text-light text-decoration-none mb-2" 
                    href="#comments" 
                    data-scroll>
                    <i 
                        className="fi-chat-circle opacity-70 me-2">
                    </i>
                    <span>3 comments</span>
                </a>
                    */}
      </div>
      <p className="fs-lg fw-bold text-light mb-4">
        I love watching anime and various anime shows have been inspirations for
        story ideas. The ranking and reviews start simiple at the begining of a
        season and change and get more fushed out as I watch.
      </p>
      <p className="text-light opacity-70">{pageText}</p>

      <p>
        <b>
          <i className="fa-duotone fa-award" style={{ color: "gold" }}></i>:
        </b>{" "}
        This anime scored a 90% or higher.
      </p>
      <p>
        <b>
          <i className="fa-duotone fa-award" style={{ color: "silver" }}></i>:
        </b>{" "}
        This anime scored between 80% and 90%.
      </p>
      <p>
        <b>
          <i
            className="fa-duotone fa-award"
            style={{ color: "sandybrown" }}
          ></i>
          :
        </b>{" "}
        This anime scored between 70% and 80%.
      </p>
      <p>
        <b>Concept:</b> This rating is focused on the "sales pitch" of the
        anime. <i>Weight: 5%</i>
      </p>
      <p>
        <b>Story:</b> This rating is focused on overall story that covers the
        whole anime. <i>Weight: 20%</i>
      </p>
      <p>
        <b>Tone:</b> This rating is focused on the general vibe of the show. Is
        it correctly implementing the concept and story? <i>Weight: 30%</i>
      </p>
      <p>
        <b>Main Character:</b> This rating is focused on how well done and
        fleshed out the main character is. <i>Weight: 20%</i>
      </p>
      <p>
        <b>Other Characters:</b> This rating is focused on how well done and
        fleshed out the other characters are. <i>Weight: 15%</i>
      </p>
      <p>
        <b>Intro Segment:</b> This rating is focused on the intro music/credits
        section, focused on the song and the clip choices. <i>Weight: 5%</i>
      </p>
      <p>
        <b>Outro Segment:</b> This rating is focused on the ending music/credits
        section, focused on the song and the clip choices. <i>Weight: 5%</i>
      </p>
      <AnimeBlocks />
    </div>
  );
};

export default Fall22;
