import LandView from "./land-view.component";

const LandShow = (land) => {
    //console.log(land);
    if (land.land.is){
        return (
            <div >
            {land.land.lands.map(thisLand => {
                
                return(
                    <div key={`cityView-${thisLand.id}`}>
                        <LandView land={thisLand}>
                        </LandView> 
                    </div>)
            })}
            
            </div>
        )
        
    }
    
}

export default LandShow;