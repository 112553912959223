import { useEffect, useContext, useState, Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import { UserContext } from "../../context/user.content";
import CreateLogArea from "./roleplayLogArea.component";
import CharacterCardList from "../../conponents/character-card-list/character-card-list.component";
import PregnancyAttempList from "./pregnancyAttempt-list.component";
import "./roleplay.styles.scss";

const Roleplay = () => {
  //let showCount = 0;
  const { displayName } = useContext(UserContext);
  const currentUserString = useContext(UserContext);
  const [rp] = useState([]);
  const [roleplay, setRoleplay] = useState([]);
  const userUid = currentUserString.uid;
  const memberType = currentUserString.mt;
  //console.log(memberType);
  const params = useParams();
  const rpId = params.rpId;

  useEffect(() => {
    let uid = "";
    userUid ? (uid = userUid) : (uid = -1);
    //console.log (uid);
    let sendData = new FormData();
    //console.log(uid);
    //console.log(rp);

    sendData.append("title", "meow");
    sendData.append("lookupType", "roleplayByKey");
    sendData.append("uid", uid);
    sendData.append("rid", rpId);
    sendData.append("version", 1);

    fetch("https://api.liltiffyrps.com/roleplayDomain/V1/getRoleplay", {
      method: "post",
      body: sendData,
    })
      .then((response) => response.json())
      .then((models) => {
        switch (models.status.code) {
          case 200:
            setRoleplay(models.rp);
            break;
          default:
          case 300:
            setRoleplay({ id: -100 });
            break;
        }
        //console.log(models.rp);
        setRoleplay(models.rp);
      });
  }, [userUid, rpId, rp]);

  const BuildCharacters = (spec) => {
    //console.log(spec.spec);
    if (spec.spec != null) {
      //console.log('family');
      //console.log(family);
      const { count, members } = spec.spec;
      //console.log('count');
      //console.log(count);
      //console.log('members');
      //console.log(members);
      if (count > 0) {
        //console.log('in if');
        return (
          <div className="bg-secondary m-2 p-2 rounded-4">
            <h2 className="text-center">Roleplay Characters ({count})</h2>
            <CharacterCardList characters={members} sizeing={3} />
          </div>
        );
      }
    }
  };

  const BuildAttempts = (spec) => {
    //console.log(spec.spec);
    if (spec.spec != null) {
      //console.log('family');
      //console.log(family);
      const { count, attempts } = spec.spec;
      //console.log('count');
      //console.log(count);
      //console.log('members');
      //console.log(members);
      if (count > 0) {
        //console.log('in if');
        return (
          <div className="bg-pink m-2 p-2 rounded-4">
            <h2 className="text-center text-black">
              Pregnancy Attempts ({count})
            </h2>
            <table className="table">
              <thead>
                <tr>
                  <th></th>
                  <th scope="col">Character</th>
                  <th scope="col">Attempt Date</th>
                  <th scope="col">Due Date</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Gender</th>
                </tr>
              </thead>

              <PregnancyAttempList attempts={attempts} />
            </table>
          </div>
        );
      }
    }
  };

  //const AttemptRow = (attempt) =>{

  //}
  ////<CharacterCardList characters={attempts} sizeing={3} />
  //<tr>
  //  <th scope="row">1</th>
  //  <td>Mark</td>
  //  <td>Otto</td>
  //  <td>@mdo</td>
  //</tr>
  const CityView = (city) => {
    //console.log(city);

    if (city.city.is) {
      //City Found
      return (
        <div className="mb-4">
          <h5 className="text-center">
            General Information{/*city.city.name*/}
          </h5>
          <p className="text-center">
            <b>Type:</b> {city.city.type} - <b>Size:</b> {city.city.size} -{" "}
            <b>Condition:</b> {city.city.condition} - <b>Age:</b>{" "}
            {city.city.age} - <b>Population Density:</b>{" "}
            {city.city.populationDensity} - <b>Population Wealth:</b>{" "}
            {city.city.populationWealth}
          </p>
        </div>
      );
    } else {
      //City Not found
    }
  };

  const ShowLocation = (roleplay) => {
    //console.log(roleplay);
    if (roleplay.roleplay.log.length > 0) {
      return (
        <Fragment>
          <div className="bg-secondary m-2 p-2 rounded-4">
            <h4 className="text-center">Current Location</h4>{" "}
            <Link className="text-center" to={`/setting/${rpId}`}>
              View Setting
            </Link>
            <div
              className="bg-dark rounded-4"
              style={{ margin: "1rem", padding: "1rem" }}
            >
              <h4>
                <i className={roleplay.roleplay.location.city.icon}></i>{" "}
                <b>{roleplay.roleplay.location.city.name}</b>
              </h4>
              <p className="fs-lg text-light mb-3">
                <i>{roleplay.roleplay.location.city.subtitle}</i>
              </p>
              {roleplay.roleplay.location.city.cardText
                .split("<br>")
                .map((it, i) => (
                  <p
                    className="text-light opacity-70 text-justify"
                    key={"locCiT" + i}
                  >
                    {it}
                  </p>
                ))}
              <CityView city={roleplay.roleplay.location.city}></CityView>
              <div
                className="bg-dark rounded-4"
                style={{ marginLeft: "1rem", paddingLeft: "1rem" }}
              >
                <h4>
                  <i className={roleplay.roleplay.location.village.icon}></i>{" "}
                  <b>{roleplay.roleplay.location.village.name}</b>
                </h4>
                <div
                  className="bg-dark rounded-4"
                  style={{ marginLeft: "1rem", paddingLeft: "1rem" }}
                >
                  <p className="fs-lg text-light mb-3">
                    <i>{roleplay.roleplay.location.village.subtitle}</i>
                  </p>
                  {roleplay.roleplay.location.village.cardText
                    .split("<br>")
                    .map((it, i) => (
                      <p
                        className="text-light opacity-70 text-justify"
                        key={"locViL" + i}
                      >
                        {it}
                      </p>
                    ))}
                  <div
                    className="bg-dark rounded-4"
                    style={{ marginLeft: "1rem", paddingLeft: "1rem" }}
                  >
                    <h4>
                      <i className={roleplay.roleplay.location.place.icon}></i>{" "}
                      <b>{roleplay.roleplay.location.place.name}</b>
                    </h4>
                    <div className="bg-dark m-2 p-2 rounded-4">
                      <p className="fs-lg text-light mb-3">
                        <i>{roleplay.roleplay.location.place.subtitle}</i>
                      </p>
                      {roleplay.roleplay.location.place.cardText
                        .split("<br>")
                        .map((it, i) => (
                          <p
                            className="text-light opacity-70 text-justify"
                            key={"locCT" + i}
                          >
                            {it}
                          </p>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
  };

  const LogForm = (roleplay) => {
    //console.log(userUid);
    if (roleplay.roleplay.keys.mt === "a") {
      /*
      return (
        <div>
          <div className="bg-secondary m-2 p-2 rounded-4">
            <h4 className="text-center">Log Form</h4>
            <form action="" method="post">
              <input type="hidden" name="bepn" value="1000" />
              <input type="hidden" name="domain" value="roleplay" />
              <input type="hidden" name="uid" value={userUid} />
            </form>
          </div>
        </div>
      );
      */
    }
  };

  const ShowLog = (log) => {
    const useLog = log.log;

    if (useLog.length > 0) {
      return (
        <div>
          {/*<hr />*/}
          <div className="bg-secondary m-2 p-2 rounded-4">
            <h4 className="text-center">Last {useLog.length} posts</h4>
            <CreateLogArea log={useLog} />
          </div>
        </div>
      );
    } else {
    }
    /*
        if (useLog.length>0){
        posts.map((post =>{
            return(
                <SingleLog
                    post={post}
                    />
            )
        }))
        } else {
            return;
        }
        */
  };
  const ShowDate = (date) => {
    //console.log(date);
    const useDate = date.date.date;
    const useWeather = date.date.weather;

    //console.log(useDate);
    if (useDate.has) {
      //console.log("in");
      return (
        <div>
          <div className="bg-dark m-2 p-2 rounded-4">
            <h2 className="text-center limeGreen">{useDate.current}</h2>
            <hr />
            <h4 className="text-center">
              {useWeather.title.name} <i className={useWeather.title.icon} />{" "}
              {useWeather.title.weather}
            </h4>
            <div className="row">
              <div className="col-4">
                <p className="h5 text-center">
                  {useWeather.hours[1].name}{" "}
                  <i className={useWeather.hours[1].icon} />{" "}
                  {useWeather.hours[1].weather}
                </p>
              </div>
              <div className="col-4">
                <p className="h5 text-center">
                  {useWeather.hours[2].name}{" "}
                  <i className={useWeather.hours[2].icon} />{" "}
                  {useWeather.hours[2].weather}
                </p>
              </div>
              <div className="col-4">
                <p className="h5 text-center">
                  {useWeather.hours[3].name}{" "}
                  <i className={useWeather.hours[3].icon} />{" "}
                  {useWeather.hours[3].weather}
                </p>
              </div>
            </div>
            <hr />
            <h4 className="text-center pb-3">
              {useDate.event.replace(/\\|\//g, "")}
            </h4>
            <ShowLocation roleplay={date.date} />
          </div>
        </div>
      );
    }
  };
  /*
    if (useLog.length > 0) {
      return (
        <div>
          {/*<hr />*/ /*
          <div className="bg-secondary m-2 p-2 rounded-4">
            <h4>{useDate.current}</h4>
            {useDate.cardTest}
          </div>
          <div className="bg-secondary m-2 p-2 rounded-4">
            <h4>Last {useLog.length} posts</h4>
            <CreateLogArea log={useLog} />
          </div>
        </div>
      );
    } else {
    }
    /*
        if (useLog.length>0){
        posts.map((post =>{
            return(
                <SingleLog
                    post={post}
                    />
            )
        }))
        } else {
            return;
        }
        */

  //console.log(roleplay);
  if (roleplay == null) {
    return <p>Loading...</p>;
  } else {
    if (roleplay.id > 0) {
      return (
        <div className="container pt-5 pb-lg-4 my-5">
          <nav className="mb-3 pb-md-1 pt-md-3" aria-label="Breadcrumb">
            <ol className="breadcrumb breadcrumb-light">
              <li className="breadcrumb-item">
                <Link to={`/`}>Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={`/myRoleplays`}>My Roleplays</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {roleplay.story.name}
              </li>
            </ol>
          </nav>
          <h1 className="h2 text-light pb-3">
            {displayName}'s {roleplay.story.name} Roleplay
          </h1>
          <img
            className="rounded-3 character-card-pic"
            src={roleplay.pictures.back}
            alt="fall 22"
          />
          <div className="row mt-4 pt-3">
            <div className="col-lg-8"></div>
          </div>
          <div className="d-flex flex-wrap border-bottom border-light pb-3 mb-4">
            <Link
              className="text-uppercase text-decoration-none border-end border-light pe-3 me-3 mb-2"
              to={roleplay.story.url}
            >
              View Story Idea
            </Link>
            <div className="d-flex align-items-center text-light border-end border-light pe-3 me-3 mb-2">
              <span>{roleplay.genre.name}</span>
            </div>
            <div className="d-flex align-items-center text-light border-end border-light pe-3 me-3 mb-2">
              <span>
                {roleplay.male.name} Age: {roleplay.male.age}
              </span>
            </div>
            <div className="d-flex align-items-center text-light border-end border-light pe-3 me-3 mb-2">
              <span>Ages: X = {roleplay.fage}</span>
            </div>
            {/*
                <div 
                    className="d-flex align-items-center text-light border-end border-light pe-3 me-3 mb-2">
                    <i 
                        className="fi-calendar-alt opacity-70 me-2">
                    </i>
                    <span>Oct 18</span>
                    </div>*/}
            {/*
                <div 
                    className="d-flex align-items-center text-light border-end border-light pe-3 me-3 mb-2">
                    <i 
                        className="fi-clock opacity-70 me-2">
                    </i>
                    <span>6 min read</span>
                </div>
                <a 
                    className="d-flex align-items-center text-light text-decoration-none mb-2" 
                    href="#comments" 
                    data-scroll>
                    <i 
                        className="fi-chat-circle opacity-70 me-2">
                    </i>
                    <span>3 comments</span>
                </a>
                    */}
          </div>
          <p className="fs-lg fw-bold text-light mb-4">{roleplay.subtitle}</p>
          <p className="text-light opacity-70 text-justify">
            {roleplay.cardText}
          </p>

          <BuildCharacters spec={roleplay.characters} />
          <BuildAttempts spec={roleplay.pregnancy} />

          <ShowDate date={roleplay} />
          <ShowLog log={roleplay.log} />
          <LogForm roleplay={roleplay} />
        </div>
      );
    }
  }
};

export default Roleplay;
