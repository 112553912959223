import { Component } from "react";
import "../../scss/colors.styles.scss";

//import CharacterCard from "../character-card/character-card.component";
//import "./character-card-list.styles.scss";

class ChangeLogUpdate extends Component {
  render() {
    const { update } = this.props;
    //let sizeText = "";
    //console.log('size');
    //console.log(update);
    //const idKey = 1;
    /*
    switch (sizeing) {
      case 3:
        sizeText = "col-sm-6 col-md-4";
        break;
      default:
        sizeText = "col-sm-6";
        break;
    }*/

    return (
      <div className="row" key={`chlst-${0}`}>
        <p>
          <span className={update.textClass}>
            <b>{update.type}</b>
          </span>{" "}
          {update.description}
        </p>
      </div>
    );
  }
}

export default ChangeLogUpdate;
