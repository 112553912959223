import { useEffect, useState } from "react";
import { loadEventIdeasState } from "../../states/event-ideas.state";
import IdeaCard from "../idea-card/idea-card.component";
//import IdeaWideCard from "../idea-card/idea-wide-card.component";

const EventIdeasList = (passes) => {
  const [ideas, setIdeas] = useState([]);

  useEffect(() => {
    loadEventIdeasState(
      passes.settings,
      ideas,
      setIdeas,
      passes.userUid,
      passes.eid
    );
  }, [passes.userUid, passes.eid, passes.settings]);

  /*
  console.log(ideas);
  console.log(ideas.length);
  console.log(passes.settings.length);
  console.log(passes.eid);
  */

  if (ideas.length === 0 || passes.settings.length !== undefined) {
    return <p>loading...</p>;
  } else if (ideas.id === -100) {
    return (
      <div>
        <h4 className="text-center mb-4">Story Ideas from this event:</h4>
        <p>There are no story ideas yet for this event</p>
      </div>
    );
  } else {
    return (
      <div>
        <h4 className="text-center mb-4">Story Ideas from this event:</h4>
        <div className="row">
          {ideas.ideas.map((idea) => {
            //const { name, id, back, subtitle} = character;
            let divClass = "";
            divClass = "col-12 col-md-6";
            //divClass = "col-12 col-md-4";
            //divClass = "col-12";

            return (
              <div className={divClass} key={idea.id}>
                <IdeaCard idea={idea} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
};

export default EventIdeasList;
