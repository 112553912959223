import { Link} from 'react-router-dom';

const RolePlayInstructions = () => {
    return (
        <div className='siteStats card bg-dark'>
            <h4>Roleplays</h4>
            <p>If you are looking to start a roleplay this should give you some help.</p>
            <h5>Plot</h5>
            <p>There are two ways of determining a basic plot for the roleplay:</p>
            <p><b>Story Ideas:</b> The most common was to chose a plot is to visit 
            the <Link to={`/ideas/all`}>story ideas page</Link> and pick one that looks interesting.  The story idea genre has been included and some story ideas have more information then others.</p>
            <p><b>Anime:</b> Alternativly if you enjoy anime you can visit my <Link to={`/anime/all`}>anime ranking page</Link>.  Here I rank various anime I've watched.  While some of the anime I've reviewed have story ideas built off of them, any of the anime listed here could be the basis of a story plot.</p>
            <h5>Characters</h5>
            <p>After the plot you will need characters.  Of course there is your character but there are often many other characters in a story.  Take a look 
            at the <Link to={`/models/all`}>all characters page</Link>.  Here you will see all the characters you have visability to.  Being logged in will show you more, and logging in with an account will show you more.  There are 100s of characters on the site but you will normally only beable to see a small fraction of that.  Pick the female character you would like for the female lead.  Most characters have friends and family tied to them so even if you can't view the characters yet they will get unlocked over time.</p>
            <p><b>Age:</b> I often get asked how the age formula works.  Ages on the site are often in terms of a formula such as X+0, X+3, or X-7.  These are used to show you how old each female character is compared to another character.  Tiffy and Erin are both X+0.  This means that they were both born in the same year.  If Tiffy is 21, Erin is 21.  If Tiffy is 18, Erin is 18.  Now, Olivia is X+2.  This means she is two years older then Tiffy and Erin.  So if Tiffy is 21, Erin is 21 and Olvia is 23.  If Tiffy is 18, Erin is 18 and Olivia is 20.  Since I have families and friends already designed for many characters this helps keep sisters the same age apart, etc.  Now, I know I said Tiffy and Erin are the same age, but Tiffy's birthday is November 3rd while Erin's is December 10th, so for a little over a month Tiffy will be a year older then Erin... but you get the point.  Also, the male main character can be any age.  A lot of people assume he's got to be X+0, but this isn't true.  This formula is used to get the female ages.  Then the male's age is just picked.      </p>
            
            
        </div>
    )
}

export default RolePlayInstructions;