import { useState } from 'react';
import { 
    signInWithGooglePopup,
    signInUserWithEmailAndPassword
} from '../../utils/firebase/firebase.utils';
import FormInput from '../form-input/form-input.component';
import Button from '../button/button.component';


import './sign-in-form.styles.scss';


const defaultFormFields = {
    email: '',
    password: '',
    
}

const SignInForm = () => {

    const [ formFields, setFormFields] = useState(defaultFormFields);
    const {  email, password} = formFields;

    //console.log(formFields);

    const SignInWithGoogle = async () => {
        await signInWithGooglePopup();
        window.location.href = '/';
    }

    const resetFormFields = () => {
        setFormFields(defaultFormFields);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        try{
            await signInUserWithEmailAndPassword(email, password);
            //setCurrentUser(user);
            resetFormFields();
            window.location.href = '/';
            
        } catch(error) {
            switch (error.code){
                case 'auth/wrong-password':
                    alert('Incorrect password for email');
                    break;
                case 'auth/user-not-found':    
                    alert('No user associated with this email');
                    break;
                default:
                    alert('Error');
                    console.log('Failed to log in: '.error.message);
                    break;
            }
            
        }

        

    }

    const handleChange = (event) => {
        const { name, value } = event.target;

        setFormFields({...formFields, [name]: value})
    }

    return (
        <div className='sign-in-container col-md-5 col-12'>
            <h2>Already have an account?</h2>
            <span>Sign in with your email and password</span>
            <form onSubmit={handleSubmit}>
                <FormInput 
                    label = 'Email'
                    type='email' 
                    required 
                    autoComplete="email"
                    onChange={handleChange} 
                    name ="email" 
                    value={email}/>
                <FormInput 
                    label = 'Password'
                    type='password' 
                    required 
                    autoComplete="current-password"
                    onChange={handleChange} 
                    name ="password" 
                    value={password}/>
                <div className='buttons-container'>
                <Button 
                    type='submit'>Sign In
                </Button>
                <Button
                    onClick={SignInWithGoogle}
                    buttonType='google'
                    type='button'
                    >Google sign in</Button>
                </div>

            </form>
            <hr />
        </div>
    )
}

export default SignInForm;