import RoleplaySearch from '../../conponents/roleplay-card-list/roleplay-card-search.component';

const MyRoleplays = () => {
      
    return (
        <div 
            className="container pt-5 pb-lg-4 my-5">
            <nav 
                className="mb-3 pb-md-1 pt-md-3" 
                aria-label="Breadcrumb">
                <ol 
                    className="breadcrumb breadcrumb-light m-0">
                    <li 
                        className="breadcrumb-item">
                        <a 
                            href="/">Home</a>
                    </li>
                    
                    
                    <li 
                        className="breadcrumb-item active" 
                        aria-current="page">My Roleplays
                    </li>
            </ol>
          </nav>
          <h1 
            className="text-light mb-4">My Roleplays</h1>
            <RoleplaySearch />    
            





        </div>


    )
}

export default MyRoleplays;