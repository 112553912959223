import PlaceShow from "../places/place-show-component";

const VillageView = (village) =>{
    //console.log(land)
    return(
        <div className='bg-dark rounded-4'
        style={{marginLeft: '1rem', paddingLeft: '1rem'}}>
            <h4><i className={village.village.icon}></i> <b>{village.village.name}</b></h4>
                            <p 
                            className="fs-lg text-light mb-3"><i>{village.village.subtitle}</i></p>
                            {village.village.cardText.split('<br>').map( (it, i) => <p className="text-light opacity-70 text-justify" key={'vlCt'+i}>{it}</p> )}
                            <PlaceShow place={village.village.place}/>
        
        </div>
    )
}

export default VillageView;