import { useContext } from "react";
import { UserContext } from "../../context/user.content";
import SearchBox from "../../conponents/search-box/search-box.component";

const CharacterSearchForm = () => {
  const { setSearchBox, setEyeRadio, setHairRadio } = useContext(UserContext);

  const onSearchChange = (event) => {
    const searchFieldString = event.target.value.toLocaleLowerCase();
    setSearchBox(searchFieldString);
    //console.log(searchField);
  };

  const onHairChange = (event) => {
    const hairRadioValue = event.target.value;
    setHairRadio(hairRadioValue);
    //console.log(searchFie);
  };

  const onEyeChange = (event) => {
    const eyeRadioValue = event.target.value;
    setEyeRadio(eyeRadioValue);
    //console.log(searchFie);
  };
  return (
    <div>
      <h6>Search Character Name</h6>
      <SearchBox
        onChangeHandler={onSearchChange}
        placeholder="Search Character Name"
        className="search-box widePiece"
      />
      <hr />
      <h6>Filter by Eye Color</h6>
      <form className="textBlack">
        <div className="radio text-wrap widePiece">
          <label>
            <input
              type="radio"
              id="allEyes"
              value=""
              name="eyeColor"
              onChange={onEyeChange}
            />
            All
          </label>
          ,{" "}
          <label>
            <input
              type="radio"
              name="eyeColor"
              id="blueHair"
              value="blue"
              onClick={onEyeChange}
            />
            Blue
          </label>
          ,{" "}
          <label>
            <input
              type="radio"
              name="eyeColor"
              id="blackHair"
              value="black"
              onClick={onEyeChange}
            />
            Black
          </label>
          ,{" "}
          <label>
            <input
              type="radio"
              name="eyeColor"
              id="brownHair"
              value="brown"
              onClick={onEyeChange}
            />
            Brown
          </label>
          ,{" "}
          <label>
            <input
              type="radio"
              name="eyeColor"
              id="grayHair"
              value="gray"
              onClick={onEyeChange}
            />
            Gray
          </label>
          ,{" "}
          <label>
            <input
              type="radio"
              name="eyeColor"
              id="greenHair"
              value="green"
              onClick={onEyeChange}
            />
            Green
          </label>
        </div>
      </form>
      <hr />
      <h6>Filter by Hair Color</h6>
      <form className="textBlack">
        <div className="radio text-wrap widePiece">
          <label>
            <input
              type="radio"
              id="allHair"
              value=""
              name="hairColor"
              onChange={onHairChange}
            />
            All
          </label>
          ,{" "}
          <label>
            <input
              type="radio"
              name="hairColor"
              id="blondeHair"
              value="Blonde"
              onClick={onHairChange}
            />
            Blonde
          </label>
          ,{" "}
          <label>
            <input
              type="radio"
              name="hairColor"
              id="brownHair"
              value="Brunette"
              onClick={onHairChange}
            />
            Brunette
          </label>
          ,{" "}
          <label>
            <input
              type="radio"
              name="hairColor"
              id="blackHair"
              value="Noirette"
              onClick={onHairChange}
            />
            Noirette
          </label>
          ,{" "}
          <label>
            <input
              type="radio"
              name="hairColor"
              id="redHair"
              value="Redhead"
              onClick={onHairChange}
            />
            Redhead
          </label>
        </div>
      </form>
    </div>
  );
};

export default CharacterSearchForm;
