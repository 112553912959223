import { Component } from "react";
import BadgePillsList from "../badge-pills-list.component/badge-pills-list.component";
import './character-card.styles.scss';

class CharacterCard extends Component{
    render(){
        const {id, name, back, subtitle} = this.props.character;
        //console.log(id);
        return(
            <div 
                className='character-card-container' 
                key={`characterCard-${id}`}
            >
                
                <div className="card character-card card-dark bg-dark card-hover h-100">
                    <div className="tns-carousel-wrapper card-img-top card-img-hover">
                        <div className="position-absolute start-0 top-0 pt-3 ps-3">
                            <BadgePillsList
                                character={this.props.character}
                            />
                            
                        </div>
                        <div className="images">
                        <a className="img-overlay" href={this.props.character.url}>
                            <img 
                                className='character-card-pic'
                                title={name.longName}
                                src={this.props.character.pictures.thumbnail} 
                                alt="Image" 
                            />
                        </a>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="d-flex align-items-center justify-content-between pb-1">
                            <span className="fs-sm text-light me-3">{subtitle}</span>
                        </div>
                        <h3 className="h6 mb-1">
                            <a className="nav-link-light" href={this.props.character.url}>{name.longName}</a>
                        </h3>
                    </div>
                    <div className="card-footer border-0 pt-0">
                        <div className="border-top border-light pt-3">
                            <div className="row g-2">
                                <div className="col me-sm-1">
                                    <div className="bg-dark rounded text-center w-100 h-100 p-2">
                                        <i className="fa-duotone fa-cake-candles d-block h4 text-light mb-0 mx-center"></i>
                                        <span className="fs-xs text-light">{this.props.character.age.formatted}</span>
                                    </div>
                                </div>
                                <div className="col me-sm-1">
                                    <div className="bg-dark rounded text-center w-100 h-100 p-2">
                                        <i className={`fa-duotone fas ${this.props.character.gender.icon} d-block h4 text-light mb-0 mx-center`}></i>
                                        <span className="fs-xs text-light">{this.props.character.gender.name}</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="bg-dark rounded text-center w-100 h-100 p-2">
                                        <i className="fa-duotone fa-flag d-block h4 text-light mb-0 mx-center"></i>
                                        <span className="fs-xs text-light">{this.props.character.race.name}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>      
        )

    }
}

export default CharacterCard;