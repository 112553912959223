import PlanetView from "./planet-view.component";

const PlanetShow = (planet) => {
    //console.log(planet);
    if (planet.planet.is){
        //console.log(planet.planet.planets);
        return (
            <div >
            {planet.planet.planets.map(thisPlanet => {
                
                return(
                    <div key={`cityView-${thisPlanet.id}`}>
                        <PlanetView planet={thisPlanet}>
                        </PlanetView> 
                    </div>)
            })}
            
            </div>
        )
        
    }
    
}

export default PlanetShow;