import { useContext, useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { UserContext } from "../../context/user.content";
import RoleplayCardList from "./roleplay-card-list";

const RoleplaySearch = () =>{

    const {pageType} = useParams();
    //const pageType = params.pageType;
    const[roleplays, setRoleplays] = useState([]);
    const currentUserString = useContext(UserContext);
    const userUid = currentUserString.uid;
    //console.log(roleplays);
      
      useEffect(()=>{
        let uid='';
        userUid ? (uid=userUid ):(uid=-1)
        //console.log(uid);
        //console.log(userUid);
        let sendData = new FormData();
        sendData.append('title', 'meow');
        sendData.append('uid', uid);
        
        fetch('https://api.liltiffyrps.com/roleplayDomain/V1/getRoleplayList', {
          method: 'post',
          body: sendData
          })
         
          .then(response=>response.json())
          .then(models => { 
            switch (models.status_code)
              {
                  case 200:
                      setRoleplays(models.body);
                      break;
                  case 300:
                    setRoleplays({id: -100});
                      break;
                  default:
                    setRoleplays({id: -200});
                      break;
              }
            //console.log(models.body);
          })
      },[userUid, pageType]); 


    //console.log(stories);
    if (roleplays.length===0){
        return (<p>loading...</p>);
    } else {
        //console.log('in else');
        for (let i=0;i<roleplays.length;i++){
            //console.log(stories[i]);
            return(
                <RoleplayCardList
                roleplays={roleplays}
                />
                
            )
        }
        


    }


}

export default RoleplaySearch;