import { Component } from "react";
import GroupCard from "./group.component";

class GroupsSection extends Component {
  render() {
    const { count, groupsList } = this.props.groups;

    if (count > 0) {
      return (
        <div>
          {groupsList.map((group) => {
            return (
              <div key={`grs-${group.key}`}>
                <GroupCard group={group} />
              </div>
            );
          })}
        </div>
      );
    } else {
      return <div></div>;
    }
    //console.log(id);
    //console.log("count");
    //console.log(count);
    //console.log('members');
    //console.log(groupsList);
    /*
    if (count > 0) {
      groupsList.map((group) => {
        console.log(group);
        return <GroupCard group={group} />;
      });
      /*
      groupsList.map((group) => {
        //console.log("group");
        console.log(group);
        //const { name, id, back, subtitle} = character;
        return (
          <div>
            <p>Mice</p>
            <GroupCard group={group} />
          </div>
        );
      });
    } else {
      return "";
    }
    */
  }
}

export default GroupsSection;
