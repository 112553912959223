import { useParams, Link } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { UserContext } from "../../context/user.content";
import BadgePillsList from "../../conponents/badge-pills-list.component/badge-pills-list.component";
import StoryCardList from "../../conponents/story-card-list/story-card-list.component";
import { loadEventsState } from "../../states/events.state";
//import EventIdeasList from "../../components/event-ideas-list/event-ideas-list.component";
import TestEventIdeasList from "./event-ideas-list.component";

const TestEventDetailPage = (passes) => {
  const params = useParams();
  const testId = parseInt(params.testId);
  const eventId = 19;
  const currentUserString = useContext(UserContext);
  const userUid = currentUserString.uid;
  const [thisEvent, setThisEvent] = useState([]);

  useEffect(() => {
    console.log("useEffect");
    if (passes.settings.length !== 0) {
      //console.log(passes.events);
      if (passes.events.length !== 0) {
        //let foundRow = false;
        //console.log(passes.events.events.length);
        for (let x = 0; x < passes.events.events.length; x++) {
          //console.log(x);
          if (passes.events.events[x].id === eventId) {
            //foundRow = true;
            //console.log(passes.events.events[x]);
            setThisEvent(passes.events.events[x]);
            return;
          }
        }
      } else {
        loadEventsState(
          passes.settings,
          passes.events,
          passes.setEvents,
          userUid
        );
      }
    }

    /*
    if (!foundRow && passes.settings.length !== 0) {
      if (passes.settings.alpha_env === "green") {
        console.log("Load/Release " + eventId + " Event");
      }
      console.log(passes.event);
      let uid = "";
      userUid ? (uid = userUid) : (uid = -1);
      let sendData = new FormData();

      sendData.append("title", passes.settings.alpha_title);
      sendData.append("env", passes.settings.alpha_env);
      sendData.append("release", passes.settings.alpha_release);
      sendData.append("rlid", passes.settings.alpha_rlid);
      sendData.append("todaysDate", passes.settings.alpha_todaysDate);
      sendData.append("version", passes.settings.event_version);

      sendData.append("eid", eventId);
      sendData.append("uid", uid);
      sendData.append("lookupType", "searchThisEvent");

      fetch("https://api.liltiffyrps.com/eventDomain/V1/getEvent", {
        method: "post",
        body: sendData,
      })
        .then((response) => response.json())
        .then((events) => {
          console.log(events);
          switch (events.status_code) {
            case 200:
              //console.log(events.body.events[0]);
              let newEvents = [];
              if (passes.event.length === 0) {
                newEvents = [];
              } else {
                newEvents = passes.event;
              }

              newEvents.push(events.body.events[0]);
              console.log(newEvents);
              passes.setEvent(newEvents);
              console.log(passes.event);
              setThisEvent(events.body.events[0]);
              console.log(thisEvent);
              break;
            case 300:
              passes.setEvent({ id: -100 });
              break;
            default:
              passes.setEvent({ id: -200 });
              break;
          }
        });
    }*/
  }, [userUid, eventId, passes.settings, passes.events]);

  const DisplayIdeasForEvent = (event) => {
    let ideas = event.event.ideas;
    let started = event.event.started;
    //ideas.cardText = ideas.cardText.replace("\\", "");
    //console.log(event);
    //console.log(started);
    ideas = "";
    if (started) {
      if (ideas === "") {
        return (
          <div>
            <h4 className="text-center mb-4">Story Ideas from this event:</h4>
            <p>There are no story ideas yet for this event</p>
          </div>
        );
      } else {
        return (
          <div>
            <h4 className="text-center mb-4">Story Ideas from this event:</h4>
            <StoryCardList stories={ideas} />
          </div>
        );
      }
    } else {
      return "";
    }
  };

  //console.log(thisEvent);
  if (thisEvent.length === 0) {
    //console.log('null');
    return <p>Loading...</p>;
  } else {
    if (thisEvent.id === -100) {
      return <p>No idea found</p>;
    } else if (thisEvent.id === -200) {
      return <p>Unknown Error loading page.</p>;
    } else {
      return (
        <div>
          <div className="container pt-5 pb-lg-4 my-5">
            {/*
            <nav className="mb-3 pb-md-1 pt-md-3" aria-label="Breadcrumb">
              <ol className="breadcrumb breadcrumb-light m-0">
                <li className="breadcrumb-item">
                  <Link to={`/`}>Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={`/events/all`}>Events</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {thisEvent.name.full.replace("\\", "")}
                </li>
              </ol>
            </nav>
      */}
            <h1 className="h2 text-light pb-3">
              {thisEvent.name.full.replace("\\", "")}
            </h1>
            <div>
              <div className="position-absolute pt-3 ps-3">
                <BadgePillsList character={thisEvent} />
              </div>
              <img
                className="rounded-3 character-card-pic"
                src={thisEvent.pictures.back}
                alt={thisEvent.name.full.replace("\\", "")}
              />
            </div>
            <div className="row mt-4 pt-3">
              <div className="col-lg-8">
                <div className="d-flex flex-wrap border-bottom border-light pb-3 mb-4"></div>
                <p className="fs-lg fw-bold text-light mb-4">
                  {thisEvent.subtitle.replace("\\", "")}
                </p>
                <p className="text-light opacity-70">
                  {thisEvent.cardText.replace("\\", "")}
                </p>
                <p className="text-light opacity-70">
                  Running {thisEvent.date.start} through {thisEvent.date.end}
                </p>
                {/*
                <p className="text-light opacity-70">
                  <b>{ideas.rpNotesStart}</b>
                </p>
                {ideas.rpNotes.split("<br>").map((it, i) => (
                  <p className="text-light opacity-70" key={"rpNotes" + i}>
                    {it}
                  </p>
                ))}

                <p className="text-light opacity-70">
                  <b>{ideas.firstStepsStart}</b>
                </p>
                {ideas.firstSteps.split("<br>").map((it, i) => (
                  <p className="text-light opacity-70" key={"firstSteps" + i}>
                    {it}
                  </p>
                ))}
                */}
                {/*}
                <div className="pt-4 pb-5 mb-md-3">
                  <div className="d-md-flex align-items-center justify-content-between border-top border-light pt-4">
                    <div className="d-flex align-items-center me-3 mb-3 mb-md-0">
                      <div className="d-none d-sm-block text-light fw-bold text-nowrap mb-2 me-2 pe-1">
                        Tags:
                      </div>
                      <div className="d-flex flex-wrap">
                        <a
                          className="btn btn-xs btn-light rounded-pill fs-sm fw-normal me-2 mb-2"
                          href={ideas.genre.url}
                        >
                          {ideas.genre.name}
                        </a>
                      </div>
                    </div>
                  </div>
            </div>*/}
              </div>
              <aside className="col-lg-4">
                <div
                  className="offcanvas offcanvas-end offcanvas-collapse bg-dark"
                  id="blog-sidebar"
                >
                  <div className="offcanvas-header bg-transparent border-bottom border-light">
                    <h2 className="h5 text-light mb-0">Sidebar</h2>
                    <button
                      className="btn-close btn-close-white"
                      type="button"
                      data-bs-dismiss="offcanvas"
                    ></button>
                  </div>
                  <div className="offcanvas-body"></div>
                </div>
              </aside>
              <div className="d-flex flex-wrap border-bottom border-light pb-3 mb-4"></div>

              <TestEventIdeasList
                settings={passes.settings}
                userUid={userUid}
                eid={eventId}
                testId={testId}
              />
              {/*<DisplayIdeasForEvent event={thisEvent} />*/}
            </div>
          </div>
        </div>
      );
    }
  }
};

export default TestEventDetailPage;
