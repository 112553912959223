import { useParams } from "react-router-dom";
import { useEffect, useState, useContext, Fragment } from "react";
import { UserContext } from "../../context/user.content";
import BadgePillsList from "../../conponents/badge-pills-list.component/badge-pills-list.component";
import { retrieveSiteSettings } from "../../utils/firebase/firebase.utils";

const StoryDetailPage = () => {
  //console.log('in');
  const params = useParams();
  const ideaId = params.ideaId;
  const [settings, setSettings] = useState([]);

  const [ideas, setIdeas] = useState([]);
  const currentUserString = useContext(UserContext);
  const userUid = currentUserString.uid;

  useEffect(() => {
    if (settings.length === 0) {
      const fetchSettings = async () => {
        setSettings(await retrieveSiteSettings());
      };
      fetchSettings();
    }
  }, []);

  useEffect(() => {
    if (settings.length !== 0) {
      let uid = "";
      userUid ? (uid = userUid) : (uid = -1);
      let sendData = new FormData();

      sendData.append("title", settings.alpha_title);
      sendData.append("env", settings.alpha_env);
      sendData.append("release", settings.alpha_release);
      sendData.append("rlid", settings.alpha_rlid);
      sendData.append("todaysDate", settings.alpha_todaysDate);
      sendData.append("version", settings.idea_version);

      sendData.append("uid", uid);
      sendData.append("lookupType", "searchThisIdea");
      sendData.append("sid", ideaId);

      fetch("https://api.liltiffyrps.com/ideaDomain/V1/getIdea", {
        method: "post",
        body: sendData,
      })
        .then((response) => response.json())
        .then((models) => {
          //console.log(models.body);
          switch (models.status_code) {
            case 200:
              setIdeas(models.body.ideas[0]);
              break;
            case 300:
              setIdeas({ id: -100 });
              break;
            default:
              setIdeas({ id: -200 });
              break;
          }
          //console.log('Response:');
          //console.log(models.body[0]);
        });
    }
  }, [userUid, ideaId, settings]);
  //console.log(params);
  //console.log(modelId);
  //console.log('Return:');
  //console.log(ideas);
  if (ideas.length === 0) {
    //console.log('null');
    return <p>Loading...</p>;
  } else {
    if (ideas.id === -100) {
      return <p>No idea found</p>;
    } else if (ideas.id === -200) {
      return <p>Unknown Error loading page.</p>;
    } else {
      //console.log('not null');
      //console.log(ideas.rpNotes);
      return (
        <div>
          <div className="container pt-5 pb-lg-4 my-5">
            <nav className="mb-3 pb-md-1 pt-md-3" aria-label="Breadcrumb">
              <ol className="breadcrumb breadcrumb-light m-0">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item">
                  <a href={ideas.genre.url}>{ideas.genre.name}</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {ideas.name}
                </li>
              </ol>
            </nav>
            <h1 className="h2 text-light pb-3">
              {ideas.name.replace("\\", "")}
            </h1>
            <div>
              <div className="position-absolute pt-3 ps-3">
                <BadgePillsList character={ideas} />
              </div>
              <img
                className="rounded-3 character-card-pic"
                src={ideas.pictures.back}
                alt={ideas.name.replace("\\", "")}
              />
            </div>
            <div className="row mt-4 pt-3">
              <div className="col-lg-8">
                <div className="d-flex flex-wrap border-bottom border-light pb-3 mb-4">
                  {/*
                            <a 
                                className="text-uppercase text-decoration-none border-end border-light pe-3 me-3 mb-2" 
                                href="#">Reviews</a>
                            <div 
                                className="d-flex align-items-center text-light border-end border-light pe-3 me-3 mb-2">
                                <i 
                                    className="fi-calendar-alt opacity-70 me-2"></i>
                                <span>Mar 25</span>
                            </div>
                            <div 
                                className="d-flex align-items-center text-light border-end border-light pe-3 me-3 mb-2">
                                <i 
                                    className="fi-clock opacity-70 me-2"></i>
                                    <span>6 min read</span>
                            </div>
                            <a 
                                className="d-flex align-items-center text-light text-decoration-none mb-2" 
                                href="#comments" 
                                data-scroll>
                                <i 
                                    className="fi-chat-circle opacity-70 me-2"></i>
                                    <span>3 comments</span>
                        </a>*/}
                </div>
                <p className="fs-lg fw-bold text-light mb-4">
                  {ideas.subtitle.replace("\\", "")}
                </p>
                <p className="text-light opacity-70">
                  {ideas.cardText.replace("\\", "")}
                </p>
                <p className="text-light opacity-70">
                  <b>{ideas.rpNotesStart}</b>
                </p>
                {ideas.rpNotes
                  .replace("\\", "")
                  .split("<br>")
                  .map((it, i) => (
                    <p className="text-light opacity-70" key={"rpNotes" + i}>
                      {it}
                    </p>
                  ))}

                <p className="text-light opacity-70">
                  <b>{ideas.firstStepsStart}</b>
                </p>
                {ideas.firstSteps
                  .replace("\\", "")
                  .split("<br>")
                  .map((it, i) => (
                    <p className="text-light opacity-70" key={"firstSteps" + i}>
                      {it}
                    </p>
                  ))}

                <div className="pt-4 pb-5 mb-md-3">
                  <div className="d-md-flex align-items-center justify-content-between border-top border-light pt-4">
                    <div className="d-flex align-items-center me-3 mb-3 mb-md-0">
                      <div className="d-none d-sm-block text-light fw-bold text-nowrap mb-2 me-2 pe-1">
                        Tags:
                      </div>
                      <div className="d-flex flex-wrap">
                        <a
                          className="btn btn-xs btn-light rounded-pill fs-sm fw-normal me-2 mb-2"
                          href={ideas.genre.url}
                        >
                          {ideas.genre.name}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <aside className="col-lg-4">
                <div
                  className="offcanvas offcanvas-end offcanvas-collapse bg-dark"
                  id="blog-sidebar"
                >
                  <div className="offcanvas-header bg-transparent border-bottom border-light">
                    <h2 className="h5 text-light mb-0">Sidebar</h2>
                    <button
                      className="btn-close btn-close-white"
                      type="button"
                      data-bs-dismiss="offcanvas"
                    ></button>
                  </div>
                  <div className="offcanvas-body"></div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      );
    }
  }
};

export default StoryDetailPage;
