import RegionView from "./region-view.component";

const RegionShow = (region) => {
    //console.log(region);
    if (region.region.is){
        //console.log(planet.planet.planets);
        return (
            <div >
            {region.region.regions.map(thisRegion => {
                
                return(
                    <div key={`cityView-${thisRegion.id}`}>
                        <RegionView region={thisRegion}>
                        </RegionView> 
                    </div>)
            })}
            
            </div>
        )
        
    }
    
}

export default RegionShow;