import { Component } from "react";
//import BadgePillsList from "../badge-pills-list.component/badge-pills-list.component";
//import './character-card.styles.scss';

class AttemptRow extends Component {
  render() {
    const { id, amount, end, start, gender, name } = this.props.attempt;
    //console.log(id);
    return (
      <tr>
        <th scope="row" key={id}></th>
        <td>{name.shortName}</td>
        <td>{start}</td>
        <td>{end}</td>
        <td>{amount}</td>
        <td>
          <b>{gender}</b>
        </td>
      </tr>
    );
  }
}

export default AttemptRow;
