import { Component } from "react";
import CharacterCardList from "../character-card-list/character-card-list.component";

class GroupCard extends Component {
  render() {
    //console.log("in");
    const { key, members, name, count, subtitle } = this.props.group;
    //console.log(id);
    return (
      <div key={`group-${key}`}>
        <hr />
        <h2>
          {name} ({count})
        </h2>
        <p>{subtitle}</p>
        <CharacterCardList characters={members} sizeing={3} />
      </div>
    );
  }
}

export default GroupCard;
