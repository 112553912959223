import { Component } from "react";

import AttemptRow from "./AttemptRow.component";
//import './character-card-list.styles.scss';

class PregnancyAttempList extends Component {
  render() {
    const { attempts } = this.props;
    //console.log(idKey);
    //console.log(this.props);
    //console.log(sizeing);
    return (
      <tbody>
        {attempts.map((attempt) => {
          //const { name, id, back, subtitle} = character;
          return <AttemptRow key={`prgrw-${attempt.id}`} attempt={attempt} />;
        })}
      </tbody>
    );
  }
}

export default PregnancyAttempList;
