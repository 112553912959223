import { Fragment, useContext, useEffect, useState } from "react";
import { Outlet, Link, useParams } from "react-router-dom";
import { UserContext } from "../../context/user.content";
import { signOutUser } from "../../utils/firebase/firebase.utils";

//import { ReactComponent as CrwnLogo } from '../../assets/crown.svg';
import "./navigation.styles.scss";

const Navigation = (settings) => {
  settings = settings.settings;
  const params = useParams();
  const modelId = params.modelId;
  const { currentUser, displayName } = useContext(UserContext);
  const currentUserString = useContext(UserContext);
  let displayNameChecked = displayName;
  const [userUa, setUserUa] = useState([]);
  const userUid = currentUserString.uid;
  const ulStyle = { maxHeight: "35rem" };

  if (userUa.length !== 0) {
    if (userUa.displayName === null) {
      displayNameChecked = "USER";
    } else {
      displayNameChecked = userUa.displayName.toUpperCase();
    }
  } else {
    displayNameChecked = "USER";
  }

  useEffect(() => {
    if (settings.length !== 0) {
      let uid = "";
      userUid ? (uid = userUid) : (uid = -1);
      let sendData = new FormData();

      sendData.append("title", settings.alpha_title);
      sendData.append("env", settings.alpha_env);
      sendData.append("release", settings.alpha_release);
      sendData.append("rlid", settings.alpha_rlid);
      sendData.append("todaysDate", settings.alpha_todaysDate);

      sendData.append("version", settings.nav_version);

      sendData.append("uid", uid);

      fetch("https://api.liltiffyrps.com/userDomain/V1/getUserUa", {
        method: "post",
        body: sendData,
      })
        .then((response) => response.json())
        .then((models) => {
          if (models.body !== userUa) {
            setUserUa(models.body);
          }
        });
    }
  }, [userUid, settings]);

  const CheckEm = () => {
    if (userUa.length !== 0) {
      if (userUa.em) {
        //console.log(userUa.em);
        return (
          <li>
            <span className="nav-link">Emulating: {userUa.ek}</span>
          </li>
        );
      }
    }
  };
  const CheckUP = () => {
    //console.log(userUa);
    if (userUa.length !== 0) {
      if (userUa.aP === "y") {
        return (
          <li>
            <Link className="dropdown-item" to={`/models/pipeline`}>
              PIPELINE CHARACTERS
            </Link>
          </li>
        );
      }
    }
  };

  const CheckUN = () => {
    if (userUa.length !== 0) {
      if (userUa.aN === "y") {
        return (
          <li>
            <Link className="dropdown-item" to={`/models/next`}>
              NEXT RELEASE CHARACTERS
            </Link>
          </li>
        );
      }
    }
  };

  const CheckUU = () => {
    if (userUa.length !== 0) {
      if (userUa.aU === "y") {
        return (
          <li>
            <Link className="dropdown-item" to={`/models/unplanned`}>
              UNPLANNED CHARACTERS
            </Link>
          </li>
        );
      }
    }
  };

  const CheckUNUU = () => {
    if (userUa.length !== 0) {
      if (userUa.aU === "y" && userUa.aN === "y") {
        return (
          <li>
            <Link className="dropdown-item" to={`/models/nextunplanned`}>
              NEXT RELEASE UNPLANNED CHARACTERS
            </Link>
          </li>
        );
      }
    }
  };

  const CheckUNUUA = () => {
    if (userUa.length !== 0) {
      if (userUa.mt === "a" && userUa.aU === "y" && userUa.aN === "y") {
        return (
          <li>
            <Link className="dropdown-item" to={`/models/nextunplannedcheck`}>
              NEXT RELEASE UNPLANNED CHARACTERS WITH PIC CHECK
            </Link>
          </li>
        );
      }
    }
  };

  const CheckRP = () => {
    if (userUa.length !== 0) {
      if (userUa.rp.active) {
        return (
          <li className="nav-item">
            <Link className="nav-link" to={`/roleplay/${userUa.rp.key}`}>
              MY ROLEPLAY
            </Link>
          </li>
        );
      }
    }
  };

  const EditChar = () => {
    //console.log(modelId);
    const url =
      "https://playground.liltiffyrps.com/index.php?page=admin&subpage=adminCharacter&gencharacter=" +
      modelId;
    if (modelId !== undefined && userUa.mt === "a") {
      return (
        <li className="nav-item">
          <a href={url} className="nav-link">
            EDIT CHARACTER
          </a>
        </li>
      );
    }
  };

  const CheckRP2 = () => {
    if (userUa.length !== 0) {
      if (userUa.rp.active || userUa.mt === "a") {
        return (
          <li className="nav-item">
            <Link className="nav-link" to={`/myRoleplays`}>
              MY ROLEPLAYS
            </Link>
          </li>
        );
      }
    }
  };

  const CheckUAll = () => {
    if (userUa.length !== 0) {
      if (userUa.aU === "y" || userUa.aN === "y" || userUa.aP === "y") {
        return (
          <li>
            <Link className="dropdown-item" to={`/models/every`}>
              ALL MODELS
            </Link>
          </li>
        );
      }
    }
  };

  return (
    <Fragment>
      <header
        className="navbar navbar-expand-lg navbar-dark fixed-top"
        data-scroll-header
      >
        <div className="container">
          <Link className="navbar-brand me-3 me-xl-4" to={`/`}>
            <img
              className="d-block"
              src="https://images.liltiffyrps.com/logo-Light.png"
              width="116"
              alt="LilTiffyRps"
            />
          </Link>
          <button
            className="navbar-toggler ms-auto"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          {/*
                        currentUser ? (
                            <p></p>
                        ) : ( 
                            <a 
                                className='btn btn-link btn-light btn-sm d-none d-lg-block order-lg-3' 
                                href='/signIn' 
                                data-bs-toggle='modal'>
                                <i className='fi-user me-2'></i>Sign in</a>
                        )
                        */}
          <div className="collapse navbar-collapse order-lg-2" id="navbarNav">
            <ul className="navbar-nav navbar-nav-scroll" style={ulStyle}>
              <li className="nav-item">
                <Link className="nav-link" to={`/`}>
                  HOME
                </Link>
              </li>
              <CheckRP />
              <li className="nav-item">
                <Link className="nav-link" to={`/anime/summer23`}>
                  SUMMER ANIME
                </Link>
              </li>
              <li className="nav-item dropdown active">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  CHARACTERS
                </a>
                <ul className="dropdown-menu dropdown-menu-dark">
                  <li>
                    <Link className="dropdown-item" to={`/models/all`}>
                      ALL CHARACTERS
                    </Link>
                  </li>
                  <CheckUN />
                  <CheckUP />
                  <CheckUU />
                  <CheckUNUU />
                  <CheckUNUUA />
                  <CheckUAll />
                </ul>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={`/events/all`}>
                  EVENTS
                </Link>
              </li>
              <li className="nav-item dropdown active">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  STORY IDEAS
                </a>
                <ul className="dropdown-menu dropdown-menu-dark">
                  <li>
                    <Link className="dropdown-item" to={`/ideas/all`}>
                      ALL STORY IDEAS
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={`/changeLog`}>
                  CHANGE LOG
                </Link>
              </li>
              {currentUser ? (
                <li className="nav-item dropdown active">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {displayNameChecked}
                  </a>
                  <ul className="dropdown-menu dropdown-menu-dark">
                    <CheckRP2 />
                    <li>
                      <span className="dropdown-item" onClick={signOutUser}>
                        SIGN OUT
                      </span>
                    </li>
                  </ul>
                </li>
              ) : (
                <li className="nav-item">
                  <Link className="nav-link" to={`/signIn`}>
                    SIGN IN
                  </Link>
                </li>
              )}
              <CheckEm />
              <EditChar />
            </ul>
          </div>
        </div>
      </header>
      {/*
            <header 
            className='navigation'>
            <Link
                className='logo-container'
                to='/'>
                <img
                    className='logo'
                    src='https://images.liltiffyrps.com/logo-Light.png'
                    alt='logo'
                    title='logo'
                    />
                </Link>
                <CheckUP />
                <Link 
                        className='nav-link'
                        to='/models'>
                        CHARACTERS
                    </Link>
                    { 
                        currentUser ? (
                            <span className='nav-link' onClick={signOutUser}>SIGN OUT</span>
                        ) : ( 
                            <Link 
                                className='nav-link'
                                to='/signIn'>
                                SIGN IN
                            </Link>
                        )
                    }
                    
            
            </header>
                */}
      <Outlet />
      <br />
      <hr />
      <footer className="footer bg-faded-light">
        <div className="container d-lg-flex align-items-center justify-content-between fs-sm pb-3">
          <p className="text-center text-lg-start order-lg-1 mb-lg-0">
            <span className="text-light opacity-50">
              &copy; All rights reserved. Made by Lil Tiffy v.0.0.1.0 All
              characters and role-play information is fictional.
            </span>
          </p>
        </div>
      </footer>
    </Fragment>
  );
};

export default Navigation;
