import { Component } from "react";
import BadgePills from "../badge-pills.component/badge-pills.component";
import './badge-pills-list.styles.scss';

class BadgePillsList extends Component{
    render(){
        const { character } = this.props;
        return(
            character.badge.map((badge =>{
                const { classText, badgeText } = badge;
                return(
                    <div key ={`bl-${badge.id}`}>
                    <BadgePills 
                        badge={badge}
                    />
                    </div>
                )
            }))
        )
    }
}
        

export default BadgePillsList;