const NeedAccount = () => {
    return (
        <div className='siteStats card bg-dark'>
            <h4>Do I really need an account?</h4>
            <p>Well, no.  Of course not.  I have a few characters and story ideas available to be viewed without signing in.</p>
            <h5>What good is an account then?</h5>
            <p>You can see more characters if you have an account.  But the biggest advantage to having an account, is it allows me to create a roleplay page specificly for your roleplay.  You can see the characters, plot, and some of the log, amoung other things.</p>
            <h5>Why don't you show all your characters to everyone?</h5>
            <p>One of the biggest complaints I recieved from new people was that the site had way too much information to dig through, specificlly the number of characters.  It was overwelming and the pages loaded very slow.  So instead I cut it back to a few choices, but gave myself the ability to unlock more character for each logged in user.</p>
        </div>
    )
}

export default NeedAccount;