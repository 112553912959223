import { Component } from "react";
import './badge-pills.styles.scss';

class BadgePills extends Component{
    render(){
        const { id, classText, badgeText } = this.props.badge;
                return(
                    <span 
                        key={id}
                        className={classText}>
                            {badgeText}
                    </span>
                
                )
    }   
        
}


export default BadgePills;