import RegionShow from '../regions/region-show-component'

const LandView = (land) =>{
    //console.log(land)
    return(
        <div className='bg-dark rounded-4'
        style={{marginLeft: '1rem', paddingLeft: '1rem'}}>
            <h4><i className={land.land.icon}></i> <b>{land.land.name}</b></h4>
                            <p 
                            className="fs-lg text-light mb-3"><i>{land.land.subtitle}</i></p>
                            {land.land.cardText.split('<br>').map( (it, i) => <p className="text-light opacity-70 text-justify" key={'plCt'+i}>{it}</p> )}
                            <RegionShow region={land.land.region}/>
        
        </div>
    )
}

export default LandView;