import LandShow from "../lands/land-show-component";

const PlanetView = (planet) =>{
    //console.log(planet)
    return(
        <div className='bg-dark rounded-4 p-2 m-2'>
            <h4><i className={planet.planet.icon}></i> <b>{planet.planet.name}</b></h4>
                            <p 
                            className="fs-lg text-light mb-3"><i>{planet.planet.subtitle}</i></p>
                            {planet.planet.cardText.split('<br>').map( (it, i) => <p className="text-light opacity-70 text-justify" key={'plCt'+i}>{it}</p> )}
                            <LandShow land={planet.planet.land}/>
        
        </div>
    )
}

export default PlanetView;