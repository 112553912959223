import { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import ChangeLogList from "../../components/changeLog.components/changeLog-list.component";
import { UserContext } from "../../context/user.content";

const ChangeLog = (settings) => {
  settings = settings.settings;
  const currentUserString = useContext(UserContext);
  const [changeLog, setChangeLog] = useState([]);
  const userUid = currentUserString.uid;

  useEffect(() => {
    if (settings.length !== 0) {
      let uid = "";
      userUid ? (uid = userUid) : (uid = -1);
      let sendData = new FormData();

      sendData.append("title", settings.alpha_title);
      sendData.append("env", settings.alpha_env);
      sendData.append("release", settings.alpha_release);
      sendData.append("rlid", settings.alpha_rlid);
      sendData.append("todaysDate", settings.alpha_todaysDate);

      sendData.append("version", settings.changeLog_version);
      sendData.append("length", settings.changeLog_length);
      sendData.append("showFuture", settings.changeLog_showFuture);

      sendData.append("uid", uid);

      fetch("https://api.liltiffyrps.com/changeLogDomain/V1/getChangeLog", {
        method: "post",
        body: sendData,
      })
        .then((response) => response.json())
        .then((log) => {
          switch (log.status_code) {
            case 200:
              setChangeLog(log.changeLog);
              break;
            default:
            case 300:
              setChangeLog({ id: -100 });
              break;
          }
        });
    }
  }, [userUid, settings]);

  if (changeLog === null) {
    return <p>Loading...</p>;
  } else {
    return (
      <div className="container pt-5 pb-lg-4 my-5">
        <nav className="mb-3 pb-md-1 pt-md-3" aria-label="Breadcrumb">
          <ol className="breadcrumb breadcrumb-light">
            <li className="breadcrumb-item">
              <Link to={`/`}>Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Change Log
            </li>
          </ol>
        </nav>
        <h1 className="h2 text-light pb-3">Change Log</h1>
        <p>
          Please note that on the day of a release, the change log updates prior
          to the release going live.
        </p>
        <ChangeLogList changeLog={changeLog} />
      </div>
    );
  }
};

export default ChangeLog;
