import { Outlet, useParams } from 'react-router-dom';

const StorySearch = () => {
    let pageTitle ='';
    const {pageType} = useParams();
    let showCrumb = false;
    let genreText = '';
    switch(pageType){
        case 'modern':
            pageTitle='Modern Story Ideas'
            showCrumb=true;
            genreText='The Modern genre is typically based off real life.  On Earth with our current technology level.  Things are kept fairly realistic with no magic or non-human races.';
            break;
        case 'gamer':
            pageTitle='Gamer Story Ideas'
            showCrumb=true;
            genreText='The genre is a blend.  It revolves around a game that the main character plays, often a full dive VRMMORPG.  For the sake of the setting the outside world is most often modern, with realistic, human only, no magic rules.  The Setting Rules apply to the in-game world only.';
            break;
        case 'isekai':
            pageTitle='Isekai Story Ideas'
            showCrumb=true;
            genreText="Isekai is a genre popular in anime and manga where the main character dies and is reincarnated or is summoned into another world.  It most often can be viewed where the past is modern but the character is suddenly in an anime fantsy genre.  The two biggest differences between Isekai and Anime fantasy is in Isekai, most of the time the character has their previous memories including modern knowleagde, and often the character is given a unique boon.";
            break;
        case 'fantasy':
            pageTitle='Fantasy Story Ideas'
            showCrumb=true;
            genreText='Fantasy takes place in the Renaissance period or before, and it may or may not have non-human races, magic, and monsters.  The biggest difference between Anime Fantasy and Fantasy is Anime Fantasy is often \'cleaner\' and blurs technology levels.  For example, a medieval period city in Anime Fantasy might have clean streets, a shop that sells candy, and an adventurer wearing shorts and a halter top while in Fantasy the streets are dirty, shops focus on blacksmithy and general goods, and people typically wear the correct clothing for the time period.'
            break;
        case 'animefantasy':
            pageTitle='Anime Fantasy Story Ideas'
            showCrumb=true;
            genreText='Anime Fantasy takes place in the Renaissance period or before, and typically has non-human races, magic, and monsters.  The biggest difference between Anime Fantasy and Fantasy is Anime Fantasy is often \'cleaner\' and blurs technology levels.  For example, a medieval period city in Anime Fantasy might have clean streets, a shop that sells candy, and an adventurer wearing shorts and a halter top while in Fantasy the streets are dirty, shops focus on blacksmithy and general goods, and people typically wear the correct clothing for the time period.'
            break;
        case 'monster':
            pageTitle='Monster Story Ideas'
            showCrumb=true;
            genreText='The monster genre involves typically a modern setting except monsters are real.  This can range from a more vampire horror to a light hearted slice of life where difference type of creatures are trying to get along.';
            break;
        case 'goddess':
            pageTitle='Goddess Story Ideas'
            showCrumb=true;
            genreText ="The Goddess genre is typically a modern setting but with a patheon of gods and goddess who walking amoung the mortals, most often secretly."
            break;
        case 'fairytale':
            pageTitle='Fairy Tale Story Ideas'
            showCrumb=true;
            genreText ="This genre focuses on classic fairy tale characters often living together in the same world.  It might be fantasy, anime fantasy or modern setting wise.";
            break;
        case 'witch':
            pageTitle='Witch Story Ideas'
            showCrumb=true;
            genreText = 'The witch genre includes modern story idea where magic is used.  This might be something like Harry Potter or could be similar to a magic girl anime.';
            break;
        case 'superhero':
            pageTitle='SuperHero Story Ideas'
            genreText = 'Superhero stories ideas involve a modern world where superheroes maybe common or the main character might be the only or the start of new superheroes.';
            showCrumb=true;
            break;
        case 'historical':
            pageTitle='Historical Story Ideas'
            showCrumb=true;
            genreText = 'Historical story ideas are ones that are based in the past but do not fall under the fantasy catagory, such as acient Rome or Greece.';
            break;
        case 'animal':
            pageTitle='Animal Story Ideas'
            showCrumb=true;
            genreText = 'The animal genre is normally a modern setting but with non-human races as well, most often taking the form of anthropamorphic animal races.';
            break;
        case 'scifi':
            pageTitle='Scifi Story Ideas'
            showCrumb=true;
            genreText='Scifi stories take place in the future.  This could be a realistic future or very fictional.';
            break;

        
        case 'pipeline':
            pageTitle='Pipeline Story Ideas'
            break;
        case 'next':
            pageTitle='Next Release Story Ideas'
            break;
        case 'unplanned':
            pageTitle='Unplanned Story Ideas'
            break;
        case 'nextunplanned':
            pageTitle='Next Release Unplanned Story Ideas'
            break;  
        case 'every':
            pageTitle='Every Story Ideas'
            break;           
        case 'all':
        default:
            pageTitle='All Story Ideas'
            break;
      }
      
      const ShowMidCrumb = () => {
        if (showCrumb) {
            return(
            <li 
                className="breadcrumb-item">
                <a 
                    href="/ideas/all">All Story Ideas</a>
            </li>)
        }
      }
      

    return (
        <div 
            className="container pt-5 pb-lg-4 my-5">
            <nav 
                className="mb-3 pb-md-1 pt-md-3" 
                aria-label="Breadcrumb">
                <ol 
                    className="breadcrumb breadcrumb-light m-0">
                    <li 
                        className="breadcrumb-item">
                        <a 
                            href="/">Home</a>
                    </li>
                    
                       <ShowMidCrumb /> 
                    
                    <li 
                        className="breadcrumb-item active" 
                        aria-current="page">{pageTitle}
                    </li>
            </ol>
          </nav>
          <h1 
            className="text-light mb-4">{pageTitle}</h1>
            <p>{genreText}</p>
            <Outlet />    
            





        </div>


    )
}

export default StorySearch;