import VillageView from "./village-view.component";

const VillageShow = (village) => {
    //console.log(land);
    if (village.village.is){
        return (
            <div >
            {village.village.villages.map(thisVillage => {
                
                return(
                    <div key={`cityView-${thisVillage.id}`}>
                        <VillageView village={thisVillage}>
                        </VillageView> 
                    </div>)
            })}
            
            </div>
        )
        
    }
    
}

export default VillageShow;