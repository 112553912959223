import PlaceView from "./place-view.component";

const PlaceShow = (place) => {
    //console.log(land);
    if (place.place.is){
        return (
            <div >
            {place.place.places.map(thisPlace => {
                
                return(
                    <div key={`cityView-${thisPlace.id}`}>
                        <PlaceView place={thisPlace}>
                        </PlaceView> 
                    </div>)
            })}
            
            </div>
        )
        
    }
    
}

export default PlaceShow;