import { Link } from "react-router-dom";

const AnimeBlock = (passed) => {
    const {anime} = passed;
    //console.log('hi');
    //console.log(anime);
    let dayText = null;
    let company = null;
    let genre =null;
    switch (anime.genre){
        case "modern":
            genre="Modern";
            break;
        case "animefantasy":
            genre="Anime Fantasy";
            break;
        case "isekai":
            genre="Isekai";
            break;
            case "scifi":
                genre="Sci-Fi";
            break;
        default:
            genre='Error';
                break;
    }
    switch (anime.company){
        case 'C':
            company="Crunchyroll";
            break;
        case 'H':
            company="HiDive";
            break;
        case 'N':
            company="Netflix";
            break;
        default:
            company="Error";
            break;

    }
    switch (anime.day){
        case 1:
            dayText='Sundays';
            break;
        case 2:
            dayText='Mondays';
            break;
            case 3:
            dayText='Tuesdays';
            break;
            case 4:
            dayText='Wednesdays';
            break;
            case 5:
            dayText='Thursdays';
            break;
            case 6:
            dayText='Fridays';
            break;
            case 7:
            dayText='Saturdays';
            break;
            default:
            dayText='Error';
            break;
        

    }

    const ShowDay = () =>{
        //console.log(anime.day);
        if (anime.day>0 && anime.current){
            return(<p><b> New Episodes:</b> {dayText}</p>)
        }
    }

    const ShowSeasons = () =>{
        let seasonText='';
        let seasonList='';
        seasonList=anime.seasons[0];
        if (anime.seasons.length>1){
            seasonText='Seasons';
            for (let x=1;x<anime.seasons.length;x++){
                seasonList=seasonList+", "+anime.seasons[x];
            }
        } else {
            seasonText='Season';
        }
        return(<p><b>{seasonText}:</b> {seasonList}</p>)
    }

    const ShowStory = () =>{
        if (anime.idea.key>0){
            return(<p><b>Story Idea based off this Anime:</b> <Link to={`/idea/${anime.idea.key}`}>{anime.idea.name} Story Idea</Link></p>)
        } else {
            return(<p><b>Story Idea based off this Anime:</b> None at this time.</p>)
        }
    }

    const ShowAward = () => {
        let showIcon = false;
        let iconColor={};
        if (anime.ratings.overall>=90){
            showIcon=true;
            iconColor={color: 'gold'};
        } else if (anime.ratings.overall>=80){
            showIcon=true;
            iconColor={color: 'silver'};
        } else if (anime.ratings.overall>=70){
            showIcon=true;
            iconColor={color: 'sandybrown'};
        }
        if (showIcon){
            return(<i className="fa-duotone fa-award" style={iconColor}
                ></i>)
        } else {
            return;
        }


    }


    //console.log (num);
    //const counter = showCount.showCount;
    return(
    <div key={`anime-${anime.count}`}>
        <h2>{anime.count}. {anime.name}</h2>
        <div className='row'>
            <div className='col-12 col-md-4'>
                <img 
                className="rounded-3 character-card-pic" 
                src={`https://images.liltiffyrps.com/backs/${anime.back}`} 
                alt="fall 22" />
            </div>
            <div className='col-12 col-md-8'>
            <p><b>Overall Score:</b> {anime.ratings.overall} <ShowAward /></p>


            
            
                <p><b>Genre:</b> <Link to={`/ideas/${anime.genre}`}>{genre}</Link></p>
                <ShowSeasons />
                <p><b>Watch On:</b> <a href={anime.url}>{company}</a></p>
                <ShowDay />
                <ShowStory />
                
                
            </div>
            <div className='col-12'>
                <p></p>
                <p><b>Anime Summary:</b> {anime.summery}</p>    
                <p><b>Lil Tiffy's Review:</b> {anime.review}</p>
                <p><b>Concept: {anime.ratings.concept.score}/10</b>: {anime.ratings.concept.text}</p>
                <p><b>Story: {anime.ratings.story.score}/10</b>: {anime.ratings.story.text}</p>
                <p><b>Tone: {anime.ratings.tone.score}/10</b>: {anime.ratings.tone.text}</p>
                <p><b>Main Character: {anime.ratings.mainCharacter.score}/10</b>: {anime.ratings.mainCharacter.text}</p>
                <p><b>Other Characters: {anime.ratings.sideCharacters.score}/10</b>: {anime.ratings.sideCharacters.text}</p>
                <p><b>Intro Segment: {anime.ratings.intro.score}/10</b>: {anime.ratings.intro.text}</p>
                <p><b>Outro Segment: {anime.ratings.outro.score}/10</b>: {anime.ratings.outro.text}</p>
            </div>

        </div>
    </div>)
    
}


export default AnimeBlock;
    