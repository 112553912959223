import { Link} from 'react-router-dom';
import { Fragment } from 'react';
import ModelStatsPanel from '../../conponents/panels/modelStats/modelStats.component';
import './home.styles.scss';
import Welcome from '../../conponents/panels/welcome/welcome.component';
import RolePlayInstructions from '../../conponents/panels/roleplayInstructions/roleplayInstructions.component';
import NeedAccount from '../../conponents/panels/needAccount/needAccount.component';

const Home = () => {
    return(
      <Fragment >
      <div 
        className = 'container mt-5 mb-md-4 py-5' >
            <div className='row'>
                <div
                    className='col-md-6 col-12'
                >
                    <img 
                        className='sitePic'
                        style={{borderRadius: 25}}
                        src='https://images.liltiffyrps.com/backs/untitled5185.png'
                        alt='tiff'
                        title='tiff'/>
                </div>
                <div
                    className='col-md-6 col-12'
                >
                    <h1
                        className='siteTitle'>
                            Welcome To LilTiffy's Role Plays
                    </h1>
                </div>
                <br />
                <br />{/*
                <div
                    className='siteStats card bg-dark' >
                        <h2 className='panelHead'>Account</h2>
                        <p className='panelText'>You are currently signed out. If you have an 
                        account please sign in to see your content.<br />
                        If you don't have an account consider creating one. Users with accounts 
                        can get the ability to see additional characters, see roleplay specific 
                        information, and more.
                        </p>
                        <div
                            className='panelLink'>
                            <Link 
                                className='nav-link'
                                to='/signIn'>
                                Sign In
                            </Link>
                        </div>
                </div> */}
                <p></p>
                <Welcome />
                <p></p>
                <NeedAccount />
                <p></p>
                <RolePlayInstructions />
                <p></p>
                <ModelStatsPanel />
            </div>
        </div>
      </Fragment>
    )
  }

  export default Home;