import BadgePillsList from "../../conponents/badge-pills-list.component/badge-pills-list.component";
import { Link } from "react-router-dom";

const IdeaWideCard = (idea) => {
  //console.log (story);
  const thisStory = idea.idea;
  //console.log(thisStory);
  return (
    <article className="pb-2 pb-md-1 mb-4 mb-md-5">
      <div className="row">
        <div className="col-md-3 col-lg-4 mb-lg-0 mb-3 mb-md-0">
          <Link className="d-block position-relative" to={thisStory.url}>
            <div className="position-absolute start-0 top-0 pt-3 ps-3">
              <BadgePillsList character={thisStory} />
            </div>
            <img
              className="rounded-3 character-card-pic"
              src={thisStory.pictures.back}
              alt="Post"
            />
          </Link>
        </div>
        <div className="col-md-9 col-lg-8">
          <Link
            className="fw-lighter text-uppercase text-decoration-none"
            to={thisStory.genre.url}
          >
            {thisStory.genre.name}
          </Link>
          <br />

          <h2 className="h5 text-light pt-1">
            <Link className="nav-link" to={thisStory.url}>
              {thisStory.name.replace("\\", "")}
            </Link>
          </h2>
          <i>
            <Link
              className="fw-lighter text-uppercase text-decoration-none"
              to={thisStory.url}
            >
              {thisStory.subtitle.replace("\\", "")}
            </Link>
          </i>
          <p className="text-light opacity-70 mb-4">
            {thisStory.cardText.replace("\\", "")}
          </p>
        </div>
      </div>
    </article>
  );
};

export default IdeaWideCard;
