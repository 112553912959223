import { useEffect, useState } from "react";
import StoryCard from "../../conponents/story-card/story-card.component";
import { loadEventIdeasState } from "../../states/event-ideas.state";
import IdeaCard from "../../components/idea-card/idea-card.component";
import IdeaWideCard from "../../components/idea-card/idea-wide-card.component";
import { loadTestEventIdeasState } from "./event-ideas.state";

const TestEventIdeasList = (passes) => {
  const [ideas, setIdeas] = useState([]);

  useEffect(() => {
    loadTestEventIdeasState(
      passes.settings,
      ideas,
      setIdeas,
      passes.userUid,
      passes.eid
    );
  }, [passes.userUid, passes.eid, passes.settings]);

  /*
  console.log(ideas);
  console.log(ideas.length);
  console.log(passes.settings.length);
  console.log(passes.eid);
  */

  if (ideas.length === 0 || passes.settings.length !== undefined) {
    return <p>loading...</p>;
  } else if (ideas.id === -100) {
    return (
      <div>
        <h4 className="text-center mb-4">Story Ideas from this event:</h4>
        <p>There are no story ideas yet for this event</p>
      </div>
    );
  } else {
    return (
      <div>
        <h4 className="text-center mb-4">Story Ideas from this event:</h4>
        <div className="row">
          {ideas.ideas.map((idea) => {
            //const { name, id, back, subtitle} = character;
            let divClass = "";
            //console.log(passes.testId);
            switch (passes.testId) {
              case 1:
                divClass = "col-12";
                return (
                  <div className={divClass} key={idea.id}>
                    <IdeaWideCard idea={idea} />
                  </div>
                );
                break;
              case 2:
                divClass = "col-12 col-md-6";
                return (
                  <div className={divClass} key={idea.id}>
                    <IdeaCard idea={idea} />
                  </div>
                );
                break;
              case 3:
              default:
                divClass = "col-12 col-md-4";
                return (
                  <div className={divClass} key={idea.id}>
                    <IdeaCard idea={idea} />
                  </div>
                );
                break;
            }
            //divClass = "col-12 col-md-6";
            //divClass = "col-12 col-md-4";
            //divClass = "col-12";
          })}
        </div>
      </div>
    );
  }
};

export default TestEventIdeasList;
